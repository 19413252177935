import { STUDENT_TYPE } from "@/core/enums"
import { StudentType } from "../services/aoeu/models"
export function getStudentType(
  campusCafeId: number | null | undefined,
  degreeSeekingStatus: boolean | null | undefined,
): StudentType {
  if (campusCafeId && degreeSeekingStatus) {
    return STUDENT_TYPE.MASTERS
  }
  if (campusCafeId && !degreeSeekingStatus) {
    return STUDENT_TYPE.COURSE_ONLY
  }
  return STUDENT_TYPE.NONE
}
