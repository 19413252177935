import { createContext } from "react"
import { AdminApiClient, ApiClient, TrackingApi } from "~/services/aoeu"

export type ResourceCtx = {
  api: ApiClient
  adminApi: AdminApiClient
  trackingApi: TrackingApi
}

export const ResourceContext = createContext<ResourceCtx>({} as ResourceCtx)
