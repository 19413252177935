import { NextParsedUrlQuery } from "next/dist/server/request-meta"
import React, { createContext, useEffect } from "react"
import { sendDataToGainsight } from "@/vendor/gainsight"
import { ServerSideAuth } from "~/auth"
import { ResourceContext } from "~/context/resource"
import { TrackingRouter } from "~/hooks/use-router"
import {
  AdminApiClient,
  ApiClient,
  ContentServiceApiClient,
  TrackingApi,
} from "~/services/aoeu"
import { clientSide } from "~/util"
import { identifyUserInHubspot } from "~/util/tracking"

export const AuthContext = createContext({} as ServerSideAuth)

export function AuthProvider({
  children,
  router,
  ssa: _ssa,
}: {
  children: React.ReactNode
  router: TrackingRouter
  ssa: ServerSideAuth
}): React.ReactElement {
  /*
   * Why React.useState(_ssa) and ssa.subscribe ?
   * - The AuthProvider state is persistent between client-side renders, but App will happily
   *   pass it an instance of _ssa with outdated data. This way, anytime auth.load(...data)
   *   is called during an API success callback, the provider will provide an updated
   *   ServerSideAuth object. */
  const [ssa, updateSsa] = React.useState(_ssa)
  ssa.subscribe(serialized => {
    updateSsa(() => ServerSideAuth.deserialize(serialized))
  }, "AuthProvider.hydration")

  /*
   * Redirect un-authenticated users if they attempt to visit a login-protected page. */
  useEffect(() => {
    const { pathname, query } = router
    ssa.guardPath(pathname, normalizeQuery(query))
  }, [ssa, router])

  /*
   * If someone is authenticated, we identify them for the benefit of the Gainsight client.
   * If any of their relevant details change, we identify them again so Gainsight has accurate
   * identifying information. */
  useEffect(() => {
    if (ssa.authenticated) {
      sendDataToGainsight(ssa.client, ssa.userData)
      identifyUserInHubspot(ssa.userData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ssa.authenticated, ssa.userData])

  const resources = {
    api: new ApiClient(),
    adminApi: new AdminApiClient(),
    contentApi: new ContentServiceApiClient(),
    trackingApi: new TrackingApi(),
  }
  if (process.env.NODE_ENV !== "production" && clientSide()) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.ssa = ssa
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.resources = resources
  }

  return (
    <AuthContext.Provider value={ssa}>
      <ResourceContext.Provider value={resources}>
        {children}
      </ResourceContext.Provider>
    </AuthContext.Provider>
  )
}

function normalizeQuery(
  query: NextParsedUrlQuery = {},
): Record<string, string> {
  const initial: Record<string, string> = {}

  return Object.entries(query).reduce((accum, [key, value]) => {
    if (!value) {
      return accum
    }

    accum[key] = Array.isArray(value) ? value[0] : value
    return accum
  }, initial)
}
