/* eslint-disable @typescript-eslint/no-explicit-any */
import { Static, TSchema, Type } from "@sinclair/typebox"
import { Value } from "@sinclair/typebox/value"
import {
  FLEXFilterOption,
  FLEXFilterOptionBox,
  FLEXSearchResult,
} from "~/services/aoeu/models"

export type SanitySearchResult = Static<
  ReturnType<typeof SanitySearchResultBox>
>
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const SanitySearchResultBox = <T extends TSchema>(result: T) =>
  Type.Object({
    results: Type.Array(result),
    filters: Type.Array(
      Type.Object({
        label: Type.String(),
        param_name: Type.String(),
        options: Type.Array(FLEXFilterOptionBox),
      }),
    ),
    meta: Type.Object({
      resultsCountByDocType: Type.Object({
        collections: Type.Number(),
        lessonPlans: Type.Number(),
        resources: Type.Number(),
        videos: Type.Number(),
        assessments: Type.Number(),
      }),
    }),
  })

export async function CastSanitySearch<T extends TSchema>(
  req: Promise<{ status: number; data: SanitySearchResult; headers?: any }>,
  schema: T,
): Promise<{
  status: number
  data: FLEXSearchResult<Static<T>>
  headers: Record<string, string>
}> {
  const { status, data, headers } = await req
  const _headers = new Headers(headers)
  const { filters, results } = Value.Cast(SanitySearchResultBox(schema), data)
  return {
    status,
    headers,
    data: {
      filters: filters.reduce((accum, filter) => {
        accum[filter.param_name] = filter.options
        return accum
      }, {} as Record<string, FLEXFilterOption[]>),
      filterNames: filters.reduce((accum, filter) => {
        accum[filter.param_name] = filter.label
        return accum
      }, {} as Record<string, string>),
      results,
      pageCount: parseInt(_headers.get("Pagination-Total-Pages") ?? "0"),
      resultCount: parseInt(_headers.get("Pagination-Total-Results") ?? "0"),
      userStandard: _headers.get("User-State-Standard") ?? undefined,
      meta: data.meta,
    },
  }
}
