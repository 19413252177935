/* eslint-disable @typescript-eslint/no-explicit-any */

export type HttpRequestErrorMeta = {
  name?: string
  status: number
  statusText: string
  data?: any
}

type PropType<TObj> = { [Property in keyof TObj]: TObj[Property] }
export type SerializedRequestError = Omit<
  PropType<HttpRequestError>,
  "toJSON" | "message" | "fromResponse"
>
export class HttpRequestError extends Error {
  name: string

  status: number

  statusText: string

  data?: any

  constructor(
    {
      status,
      statusText,
      data,
      name = "HttpRequestError",
    }: HttpRequestErrorMeta,
    ...args: any[]
  ) {
    super(...args)
    this.name = name
    this.status = status
    this.statusText = statusText
    this.data = data

    if (
      Error.captureStackTrace &&
      typeof Error.captureStackTrace === "function"
    ) {
      Error.captureStackTrace(this, HttpRequestError)
    }
  }

  toJSON(): SerializedRequestError {
    return {
      name: this.name,
      status: this.status,
      statusText: this.statusText,
      data: this.data,
    }
  }

  static fromResponse(
    res: Response,
    message?: string,
    meta?: Partial<SerializedRequestError>,
  ): HttpRequestError {
    return new HttpRequestError(
      {
        status: res.status,
        statusText: res.statusText,
        data: null,
        name: res.statusText,
        ...meta,
      },
      message
        ? `[${res.status}]: ${res.statusText} - ${message}`
        : `[${res.status}]: ${res.statusText}`,
    )
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const asHTTPError = <T>(e: any, data?: T): HttpRequestError =>
  e instanceof HttpRequestError
    ? e
    : new HttpRequestError({ status: 501, statusText: "", data: data ?? {} })
