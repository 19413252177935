import { TContentType } from "@/apps/flex/util"
import { isEmpty, rstrip, strip } from "~/util"

export const pages = {
  home: "/",
  termsAndConditions: "/terms-and-conditions",
  user: {
    login: "/user/login",
    logout: "/user/logout",
    create: "/user/create",
    error: "/user/error",
    verifyEmail: "/user/verify-email",
    resendVerifyEmail: "/user/resend-verify-email",
    unverifiedEmail: "/user/unverified-email",
    profile: "/user/profile",
    subscriptions: "/user/subscriptions",
    courses: "/user/courses",
    customizeYourExperience: "/user/customize-your-experience",
    social: "/user/social",
  },
  purchase: {
    flex: {
      home: "/purchase/flex",
      pay: (pid: string): string => `/purchase/flex/pay?pid=${pid}`,
      success: (pid: string): string => `/purchase/flex/success?pid=${pid}`,
    },
    pro: {
      home: "/purchase/pro",
      pay: (pid: string): string => `/purchase/pro/pay?pid=${pid}`,
      success: (pid: string): string => `/purchase/pro/success?pid=${pid}`,
    },
    nowConference: {
      home: "/purchase/now-conference",
      pay: (pid: string): string => `/purchase/now-conference/pay?pid=${pid}`,
      success: (pid: string): string =>
        `/purchase/now-conference/success?pid=${pid}`,
    },
  },
  flex: {
    home: "/flex",
    search: "/flex/search",
    curricula: {
      index: "/flex/curricula",
      detail: (id: ResourceId): string => `/flex/curricula/${id}`,
    },
    collections: {
      index: generateFlexSearchUrlByContentType("collection"),
      detail: (id: ResourceId): string => `/flex/collections/${id}`,
    },
    lessonPlans: {
      index: generateFlexSearchUrlByContentType("lesson_plan"),
      detail: (id: ResourceId): string => `/flex/lesson-plans/${id}`,
    },
    myClasses: {
      /**
       * @deprecated use myLibrary.myClasses.index
       */
      index: "/flex/my-classes",
      /**
       * @deprecated use myLibrary.myClasses.detail
       */
      detail: (id: ResourceId): string => `/flex/my-classes/${id}`,
      /**
       * @deprecated use myLibrary.myClasses.unit
       */
      unit: (unitId: ResourceId, classId?: ResourceId): string =>
        isEmpty(classId)
          ? `/flex/my-classes/units/${unitId}` // next.config rewrites this to the appropriate page
          : `/flex/my-classes/${classId}/units/${unitId}`,
    },
    myLibrary: {
      index: "/flex/my-library",
      bookmarks: "/flex/my-library/bookmarks",
      myClasses: {
        index: "/flex/my-library/my-classes",
        detail: (id: ResourceId): string => `/flex/my-library/my-classes/${id}`,
        unit: (unitId: ResourceId, classId?: ResourceId): string =>
          isEmpty(classId)
            ? `/flex/my-library/my-classes/units/${unitId}` // next.config rewrites this to the appropriate page
            : `/flex/my-library/my-classes/${classId}/units/${unitId}`,
      },
    },
    assessments: {
      index: generateFlexSearchUrlByContentType("assessment"),
      detail: (id: ResourceId): string => `/flex/assessments/${id}`,
    },
    resources: {
      index: generateFlexSearchUrlByContentType("resource"),
      detail: (id: ResourceId): string => `/flex/resources/${id}`,
    },
    videos: {
      index: generateFlexSearchUrlByContentType("video"),
      detail: (id: ResourceId): string => `/flex/videos/${id}`,
    },
    myStandards: "/flex/my-standards",
    implementation: "/flex/implementation",
    implementationGroup: (activeTab: string, featureId?: string): string =>
      isEmpty(featureId)
        ? `/flex/implementation?activeTab=${activeTab}`
        : `/flex/implementation?activeTab=${activeTab}&featureId=${featureId}`,
  },
  pro: {
    home: "/pro",
    search: "/pro/search",
    packs: "/pro/packs",
    implementation: "/pro/implementation",
    implementationGroup: (activeTab: string, featureId?: string): string =>
      isEmpty(featureId)
        ? `/pro/implementation?activeTab=${activeTab}`
        : `/pro/implementation?activeTab=${activeTab}&featureId=${featureId}`,
  },
  currentNowConference: "/now",
  pageNotFound: (referer?: string): string =>
    `/404${referer ? `?referer=${referer}` : ""}`,
}
type ResourceId = string | number

export const products = {
  masters: "/masters/",
  academics: "/academics/",
  flex: "/flex/",
  pro: "/pro/",
  graduateCourses: "/courses/",
  conference: "/now/",
  magazine: "/magazine/",
  proPacks: "/pro/packs/",
  exploreCollections: "/flex/collections/",
  nowConference: "/products/event/now/",
}

const FEATURE_BASE = (() => {
  const stripped = strip(
    process.env.NEXT_PUBLIC_FEATURE_SERVICE_URL ?? "/features/api/v1/",
    "/",
  )
  try {
    new URL(stripped)
    return `${stripped}`
  } catch (e) {
    return `/${stripped}`
  }
})()

/* dynamic env access (e.g. process.env[VAR_NAME]) does not work due
  to the way the bundler interprets process.env properties, so we must
  check manually for each. */
const MISSING_ENV_VARS: string[] = []
if (!process.env.NEXT_PUBLIC_AOEU_API_URL)
  MISSING_ENV_VARS.push("NEXT_PUBLIC_AOEU_API_URL")
if (!process.env.NEXT_PUBLIC_MARKETING_URL)
  MISSING_ENV_VARS.push("NEXT_PUBLIC_MARKETING_URL")
if (!process.env.NEXT_PUBLIC_CAMPUS_CAFE_URL)
  MISSING_ENV_VARS.push("NEXT_PUBLIC_CAMPUS_CAFE_URL")
if (!process.env.NEXT_PUBLIC_MARKETING_URL)
  MISSING_ENV_VARS.push("NEXT_PUBLIC_MARKETING_URL")
if (!process.env.NEXT_PUBLIC_CAMPUS_CAFE_SSO)
  MISSING_ENV_VARS.push("NEXT_PUBLIC_CAMPUS_CAFE_SSO")
if (!process.env.NEXT_PUBLIC_BRIGHTSPACE_HE_URL)
  MISSING_ENV_VARS.push("NEXT_PUBLIC_BRIGHTSPACE_HE_URL")
if (!process.env.NEXT_PUBLIC_BRIGHTSPACE_PD_URL)
  MISSING_ENV_VARS.push("NEXT_PUBLIC_BRIGHTSPACE_PD_URL")
if (!process.env.NEXT_PUBLIC_BRIGHTSPACE_PD_DEPARTMENT_ORG_ID)
  MISSING_ENV_VARS.push("NEXT_PUBLIC_BRIGHTSPACE_PD_DEPARTMENT_ORG_ID")
if (!process.env.NEXT_PUBLIC_BRIGHTSPACE_PD_LANDING_ORG_ID)
  MISSING_ENV_VARS.push("NEXT_PUBLIC_BRIGHTSPACE_PD_LANDING_ORG_ID")
if (!process.env.NEXT_PUBLIC_EVENT_TRACKING_API_URL)
  MISSING_ENV_VARS.push("NEXT_PUBLIC_EVENT_TRACKING_API_URL")
if (!process.env.NEXT_PUBLIC_EVENT_TRACKING_API_KEY)
  MISSING_ENV_VARS.push("NEXT_PUBLIC_EVENT_TRACKING_API_KEY")
if (MISSING_ENV_VARS.length)
  throw new Error(
    `The follow ENV VARS are unset: ${MISSING_ENV_VARS.join(", ")}`,
  )

export const MARKETING_BASE = rstrip(
  process.env.NEXT_PUBLIC_MARKETING_URL!,
  "/",
)
export const externalUrls = (() => {
  const marketingBase = MARKETING_BASE
  const campusCafeBase = rstrip(process.env.NEXT_PUBLIC_CAMPUS_CAFE_URL!, "/")
  const bsProDevelopmentBase = rstrip(
    process.env.NEXT_PUBLIC_BRIGHTSPACE_PD_URL!,
    "/",
  )
  return {
    marketing: {
      home: marketingBase,
      magazine: `${marketingBase}/magazine`,
      community: `https://community.theartofeducation.edu`,
      pro: `${marketingBase}/pro`,
      flex: `${marketingBase}/flex`,
      now: `${marketingBase}/now`,
      masters: `${marketingBase}/masters`,
      courses: `${marketingBase}/courses`,
      nowAttendee: `${marketingBase}/now-attendee`,
      nowInYourSchool: `${marketingBase}/now-in-your-school`,
      flexProInYourSchool: `${marketingBase}/get-flex-pro-in-your-school/`,
      privacyPolicy: `${marketingBase}/documents/privacy-policy/`,
    },
    student: {
      campusCafeSso: process.env.NEXT_PUBLIC_CAMPUS_CAFE_SSO!,
      apply: `${campusCafeBase}/cafeweb/tapestry?service=home&mode=1`,
    },
    pro: {
      completedPacks: `${bsProDevelopmentBase}/d2l/awards/${process.env.NEXT_PUBLIC_BRIGHTSPACE_PD_DEPARTMENT_ORG_ID}/#/myAwards`,
      inProgressPacks: `${bsProDevelopmentBase}/d2l/home/${process.env.NEXT_PUBLIC_BRIGHTSPACE_PD_LANDING_ORG_ID}/`,
    },
    launchNow: "https://artofed.info/MyNOW",
    help: "https://help.theartofeducation.edu",
    contact: `${marketingBase}/contact`,
  }
})()

export const api = {
  baseUrl: process.env.NEXT_PUBLIC_AOEU_API_URL!,
  user: (userId?: string): string =>
    userId ? `/v1/user/${userId}` : "/v1/user/",
  userProfile: (userId: string): string => `/v1/user/${userId}/profile/`,
  userEmail: (userId: string, emailId?: number): string =>
    emailId
      ? `/v1/user/${userId}/email/${emailId}/`
      : `/v1/user/${userId}/email/`,
  userRole: (userId: string, roleId?: string): string =>
    roleId ? `/v1/user/${userId}/role/${roleId}/` : `/v1/user/${userId}/role/`,
  userSetPrimaryEmail: (id: string): string =>
    `/v1/user/${id}/set-primary-email/`,
  userSendVerificationEmail: (): string => `/v1/auth/resend-verify-email/`,
  userSubscriptions: ({ userId }: { userId: string }): string =>
    `/v1/user/${userId}/subscriptions`,
  userTickets: ({ userId }: { userId: string }): string =>
    `/v1/user/${userId}/tickets`,
  userNOWConferences: `/v1/now-conferences/`,
  allNOWConferences: `/v1/now-conferences/all`,
  nowCertificate: (productName: string): string =>
    `/v1/generate/now-cert/${productName}/`,
  authVerifyEmail: "/v1/auth/verify-email/",
  authVerifyLink: "/v1/auth/verify-email-link/",
  authLogin: "/v1/auth/login/",
  authLogout: "/v1/auth/logout",
  refresh: (): string => `/v1/auth/refresh/`,
  authRefresh: "/v1/auth/refresh/",
  providerLogin: "/v1/auth/login/oidc/",
  bsSso: {
    proCourse: (userId: string): string => `/v1/user/${userId}/pro-dev-sso/`,
    pdRegister: (userId: string): string =>
      `/v1/user/${userId}/pro-dev-register/`,
    higherEd: (userId: string): string => `/v1/user/${userId}/higher-edu-sso/`,
  },
  resendVerifyEmail: "/v1/auth/resend-verify-email/",
  forgotPassword: "/v1/auth/forgot-password/",
  resetPasswordLink: "/v1/auth/reset-password-link/",
  addEmail: (userId: string): string => `/v1/user/${userId}/email`,
  setPrimaryEmail: (userId: string): string =>
    `/v1/user/${userId}/set-primary-email`,
  deleteEmail: ({
    userId,
    emailId,
  }: {
    userId: string
    emailId: number
  }): string => `/v1/user/${userId}/email/${emailId}`,
  studentInfo: ({ userId }: { userId: string }): string =>
    `/v1/user/${userId}/student-info/`,
  products: "/v1/product/",
  productPrices: "/v1/product/prices",
  customerPortal: "/v1/customer-portal/",
  taxEstimate: "/v1/tax-estimate",
  startSubscriptionPurchase: "/v1/product/subscription/secret",
  completeSubscriptionPurchase: "/v1/product/subscription/create",
  validateSubscriptionCoupon: "/v1/product/subscription/coupon/validate",
  startProductPurchase: "/v1/product/purchase/secret",
  completeProductPurchase: "/v1/product/purchase/create",
  validateProductCoupon: "/v1/product/purchase/coupon/validate",
  roles: (): string => `v1/role/`,
  features: {
    flags: `${FEATURE_BASE}/flags/`,
    traits: `${FEATURE_BASE}/traits/bulk/`,
    userFlags: `${FEATURE_BASE}/identities/`,
  },
  resendDistrictAdminWelcomeEmail: (orgId?: number): string =>
    `/v1/organization/${orgId}/resend-welcome-email`,
  org: (orgId?: number): string =>
    orgId ? `/v1/organization/${orgId}/` : "/v1/organization/",
  orgProductMetrics: (orgId: number): string =>
    `/v1/organization/${orgId}/metrics/`,
  orgUser: (orgId: number, userId?: string): string =>
    userId
      ? `/v1/organization/${orgId}/user/${userId}/`
      : `/v1/organization/${orgId}/user/`,
  orgRemoveUsers: (orgId: number): string =>
    `/v1/organization/${orgId}/user/bulk-remove/`,
  orgEntitlement: (orgId: number): string =>
    `/v1/organization/${orgId}/entitlement/`,
  orgEntitlementUpload: (orgId: number): string =>
    `/v1/organization/${orgId}/upload/`,
  createUserDirect: (): string => "/v1/user/create-direct",
  class: {
    list: "/v1/class/",
    create: "/v1/class/",
    detail: (classId: ResourceId): string => `/v1/class/${classId}/`,
    ops: {
      reorder: `/v1/class/reorder/`,
      assignOwner: (classId: ResourceId): string =>
        `/v1/class/${classId}/assign-owner/`,
      assignUnits: (classId: ResourceId): string =>
        `/v1/class/${classId}/assign-units/`,
      removeUnits: (classId: ResourceId): string =>
        `/v1/class/${classId}/remove-units/`,
      clone: (classId: ResourceId): string => `/v1/class/${classId}/clone/`,
      cloneCurriculum: (curriculumId: ResourceId): string =>
        `/v1/class/curriculum/${curriculumId}/`,
      appendAsset: (classId: ResourceId): string =>
        `/v1/class/${classId}/append-asset/`,
      bulkAssetOp: `/v1/class/asset/bulk-op`,
    },
  },
  unit: {
    create: "/v1/unit/",
    detail: (unitId: ResourceId): string => `/v1/unit/${unitId}/`,
    ops: {
      clone: (unitId: ResourceId): string => `/v1/unit/${unitId}/clone/`,
      appendAsset: (unitId: ResourceId): string =>
        `/v1/unit/${unitId}/append-asset/`,
    },
  },
  bookmark: {
    list: "/v1/bookmark/",
    get: (bookmarkId: ResourceId): string => `/v1/bookmark/${bookmarkId}/`,
    getByAssetId: (assetId: ResourceId): string =>
      `/v1/bookmark/asset/${assetId}`,
    create: "/v1/bookmark/",
    delete: (bookmarkId: ResourceId): string => `/v1/bookmark/${bookmarkId}/`,
  },
}

const cdnFLEXBaseUrl = process.env.NEXT_PUBLIC_CONTENT_CDN_PREFIX
export const ContentAPI = {
  baseUrl: String(new URL("/v1", process.env.NEXT_PUBLIC_AOEU_API_URL)),
  cdnBaseUrl: cdnFLEXBaseUrl,
  image: (id: string): string => `${cdnFLEXBaseUrl}/${id}`,
  lessonPlans: "/flex/lesson-plans/search",
  lessonPlan: (id: ResourceId): string => `/flex/lesson-plans/${id}`,
  collections: "/flex/collections/search",
  collection: (id: ResourceId): string => `/flex/collections/${id}`,
  resources: "/flex/resources/search",
  resource: (id: ResourceId): string => `/flex/resources/${id}`,
  videos: "/flex/videos/search",
  video: (id: ResourceId): string => `/flex/videos/${id}`,
  assessments: "/flex/assessments/search",
  assessment: (id: ResourceId): string => `/flex/assessments/${id}`,
  standards: "/flex/my-standards/search",
  standard: (id: ResourceId): string => `/flex/my-standards/${id}`,
  packs: "/pro/packs/search",
  implementations: "/implementations/search",
  implementation: (id: ResourceId): string => `/implementations/${id}`,
  curricula: `/flex/curricula`,
  curriculum: (slug: string): string => `/flex/curricula/${slug}`,
}

export const TrackingAPI = {
  baseUrl: new URL(process.env.NEXT_PUBLIC_EVENT_TRACKING_API_URL!),
  event: "/event",
}

const STRIPE_DASHBOARD_BASE =
  process.env.NEXT_PUBLIC_STRIPE_DASHBOARD ??
  "https://dashboard.stripe.com/test"
export const adminUrls = {
  siteRoot: "/",
  home: "/admin",
  users: "/admin/users",
  user: (id: string): string => `/admin/users/${id}`,
  orgs: "/admin/organizations",
  org: (id: number): string => `/admin/organizations/${id}`,
  orgReports: (id: number): string => `/admin/organizations/${id}/reports`,
  flex: "/flex",
  pro: "/pro-learning",
  masters: "/masters-degree",
  graduateCourses: "/graduate-courses",
  magazine: "/magazine",
  conference: "/now-conference",
  login: "/user/login",
  logout: "/user/logout",
  profile: "/user/profile",
  stripeCustomer: (id?: string, email?: string): string => {
    if (id) return `${STRIPE_DASHBOARD_BASE}/customers/${id}`

    const baseUrl = new URL(`${STRIPE_DASHBOARD_BASE}/customers`)
    if (!email) return String(baseUrl)
    baseUrl.searchParams.set("email", email)
    return String(baseUrl)
  },
}

export function generateFlexSearchParamsByContentType(
  contentType?: TContentType,
): URLSearchParams {
  const params = new URLSearchParams()

  if (contentType) {
    params.append("refinementList[type][0]", contentType)
  }

  params.append("sortBy", "newest")
  return params
}

export function generateFlexSearchUrlByContentType(
  contentType: TContentType,
): string {
  const params = generateFlexSearchParamsByContentType(contentType)
  return `/flex/search?${params.toString()}`
}
