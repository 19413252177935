import { createStitches } from "@stitches/react"

export const colors = {
  // **** Brand Color Palette ****
  aoeuBlue: "#00AFD7",
  aoeuBlue2: "#007DB6",
  aoeuBlue3: "#009ad7",
  darkBlue: "#0076A8",
  darkGray: "#545555",
  rioGrande: "#C4D600",
  appleGreen: "#84BD00",
  observatory: "#00966C",
  tiffanyBlue: "#00B2A9",
  allPorts: "#0076A8",
  indigo: "#486CC7",
  toolbox: "#7474C1",
  purpleMountainsMajesty: "#A57FB2",
  freeSpeechMagenta: "#E45DBF",
  vividCerise: "#DF1995",
  shiraz: "#BA0C2F",
  flamingo: "#E5554F",
  mangoTango: "#EA7600",
  sunglow: "#FFC845",
  oracle: "#395056",
  athensGray: "#EBF0F3",
  kinglyCloud: "#dedede",
  white: "#ffffff",
  black: "#000000",
  whiteSmoke: "#f5f5f5",
  baseTextColor: "#002936",
  mainText: "$darkGray",
  red: "#CD163F",
  stroke: "#395056",
  lightGreen: "#C4D82E",
  sidewalkGray: "#7B9199",
  blueFlame: "#005f8a",
  gunmetalGray: "#6E797E",
  mountainPine: "#394F55",
  orangeade: "#E3582C",
  lakePlacid: "#B0B9BB",
  pantone404: "#776E64",

  buttonDisabledBackground: "#dbdbdb",
  buttonDisabledForeground: "$gunmetalGray",
  textFieldErrorBorderColor: "$orangeade",

  gray50: "#F9FAFB",
  gray100: "#F3F4F6",
  gray200: "#E5E7EB",
  gray300: "#D1D5DB",
  gray400: "#9CA3AF",
  gray500: "#6B7280",
  gray600: "#4B5563",
  gray700: "#374151",
  gray800: "#1F2937",
  gray900: "#111827",

  yellow50: "#FFFBEB",
  yellow100: "#FEF3C7",
  yellow200: "#FDE68A",
  yellow300: "#FCD34D",
  yellow400: "#FBBF24",
  yellow500: "#F59E0B",
  yellow600: "#D97706",
  yellow700: "#B45309",
  yellow800: "#92400E",
  yellow900: "#78350F",

  // **** Product Colors ****
  // Product: Masters
  mastersPrimary: "$aoeuBlue",
  mastersSecondary: "$darkBlue",
  mastersTertiary: "#CFCFCF",
  // Product: Graduate Courses
  gradCoursesPrimary: "#485CC7",
  gradCoursesSecondary: "#7474C1",
  gradCoursesTertiary: "$aoeuBlue",
  // Product: FLEX Curriculum
  flexPrimary: "$tiffanyBlue",
  flexSecondary: "#4CC7E3",
  flexTertiary: "$allPorts",
  // Product: PRO Learning
  proPrimary: "$flamingo",
  proSecondary: "$sunglow",
  proTertiary: "#F7BD98",
  // Product: NOW Conference
  nowConferencePrimary: "$freeSpeechMagenta",
  nowConferenceSecondary: "$rioGrande",
  nowConferenceTertiary: "$mangoTango",
  // Product: Magazine & Media
  magazinePrimary: "$appleGreen",
  magazineSecondary: "$rioGrande",
  magazineTertiary: "$observatory",
}

export const breakpointPx = {
  initial: 0,
  xs: 320,
  s: 512,
  sm: 640,
  m: 768,
  ml: 960,
  l: 1024,
  xl: 1280,
  xxl: 1440,
} as const

export const breaks = Object.entries(breakpointPx).reduce(
  (breakPoints, [key, value]) => {
    breakPoints[key as keyof typeof breakpointPx] = `${value}px`

    return breakPoints
  },
  {} as Record<keyof typeof breakpointPx, string>,
)

export const sizes = {
  1: "0.25rem",
  2: "0.5rem",
  3: "1rem",
  4: "1.5rem",
  5: "2rem",
  6: "2.5rem",
  7: "3rem",
  8: "3.5rem",
  9: "4rem",
  10: "4.5rem",
  11: "5rem",
  contentWidth: "min($breaks$xxl - 1.3rem * 2, 100% - 3rem)",
  appBarHeight: "$space$9",
}

export const weights = {
  light: "300",
  regular: "400",
  medium: "500",
  semiBold: "600",
  bold: "700",
}

export const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
} = createStitches({
  theme: {
    colors,
    shadows: colors,
    fonts: {
      primary: `"proxima-nova", Helvetica, Arial, sans-serif`,
      secondary: `"chaparral-pro", Georgia, serif`,
    },
    fontSizes: { ...sizes },
    space: { ...sizes },
    sizes,
    weights,
    breaks,
  },
  media: {
    initial: `(min-width: ${breaks.initial})`,
    xs: `(min-width: ${breaks.xs})`,
    s: `(min-width: ${breaks.s})`,
    sm: `(min-width: ${breaks.sm})`,
    m: `(min-width: ${breaks.m})`,
    ml: `(min-width: ${breaks.ml})`,
    l: `(min-width: ${breaks.l})`,
    xl: `(min-width: ${breaks.xl})`,
    xxl: `(min-width: ${breaks.xxl})`,
  },
  utils: {
    smoothTransition: (value: string) => {
      const propertiesToTransition = value.split(",")
      const transitionDuration = "250ms"
      const transitionEasing = "ease-in-out"

      const transitionProperties = propertiesToTransition.map(
        p => `${p.trim()} ${transitionDuration} ${transitionEasing}`,
      )

      return {
        transition: transitionProperties.join(", "),
      }
    },
  },
})

const AOEU_BLUE_RGB = "0, 175, 215"

/**
 * For styling overrides see {@link https://github.com/algolia/autocomplete/blob/next/packages/autocomplete-theme-classic/src/theme.scss theme.scss}
 * This encapsulates all styling for the Autocomplete panel (including when detached).
 * For styling the input, see {@link AutocompleteContainer} in `Autocomplete.tsx`.
 */
const AutocompleteStyles = {
  ":root": {
    "--aa-detached-media-query": "(max-width: 767px) !important",
    "--aa-detached-modal-media-query": "none !important",
  },

  ".aa-Detached": {
    ".aa-Input": {
      paddingLeft: "$2",
    },
  },

  ".aa-DetachedOverlay": {
    // Input
    "--aa-search-input-height": "44px",
    "--aa-input-icon-size": "16px",

    // Colors
    "--aa-primary-color-rgb": AOEU_BLUE_RGB,

    // Size and spacing
    "--aa-base-unit": "16",
    "--aa-spacing-factor": "1",
    "--aa-spacing":
      "calc(var(--aa-base-unit) * var(--aa-spacing-factor) * 1px)",
    "--aa-spacing-half": "calc(var(--aa-spacing) / 2)",

    // Border colors
    "--aa-input-border-color-rgb": AOEU_BLUE_RGB,
    "--aa-input-border-color-alpha": "0.8",

    ".aa-Form": {
      "borderRadius": "4px",
      "flexDirection": "row-reverse",

      "&:focus-within": {
        ".aa-Input": {},
      },
    },

    ".aa-SubmitButton": {
      cursor: "pointer",
    },
  },

  ".aa-Panel": {
    "--aa-panel-border-color-rgb": AOEU_BLUE_RGB,
    "--aa-panel-border-color-alpha": "0.8",
    "--aa-panel-max-height": "clamp(350px, calc(50vh), 650px)",
    "zIndex": "1000",
    "border-radius": "4px !important",
  },
}

export const globalStitchesStyles = globalCss({
  "*": { boxSizing: "border-box" },
  "html,body": {
    "fontFamily": "$primary",
    "fontSize": "$3",
    "-webkit-font-smoothing": "antialiased",
    "-moz-osx-font-smoothing": "grayscale",
  },
  "body": {
    color: "$baseTextColor",
    margin: 0,
    padding: 0,
  },
  ".main-app": {
    "position": "relative",
    ".svgbox-icon": {
      display: "inline-block",
      height: "$4",
      width: "$4",
    },
    // remove the "x" from search inputs on IE
    "input[type=search]::-ms-clear": {
      display: "none",
      width: "0",
      height: "0",
    },
    "input[type=search]::-ms-reveal": {
      display: "none",
      width: "0",
      height: "0",
    },
    // remove the "x" from search inputs on Chrome
    [`input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration`]: {
      display: "none",
    },
    "@page": {
      size: "A4",
      margin: "0.2cm",
    },
    "@media print": {
      ".screen-only": {
        display: "none !important",
      },
      ".MuiAppBar-root": {
        display: "none !important",
      },
      ".MuiDrawer-root": {
        display: "none !important",
      },
    },
  },
  ...AutocompleteStyles,
})
