import RefreshIcon from "@mui/icons-material/Refresh"
import CircularProgress from "@mui/material/CircularProgress"
import IconButton from "@mui/material/IconButton"
import React, { ReactElement } from "react"
import { styled } from "@/styles/stitches.config"

export type ActionIconProps = {
  active: boolean
  icon?: ReactElement
  progressIndicator?: ReactElement
  tooltip?: string
  onClick?: () => void
  className?: string
}

export const ActionIcon = styled(
  function ({
    active,
    icon = <RefreshIcon fontSize="small" />,
    progressIndicator = (
      <CircularProgress color="inherit" size={15} disableShrink />
    ),
    tooltip,
    onClick = () => {},
    className,
  }: ActionIconProps): ReactElement {
    return (
      <div className={className}>
        {!active ? (
          <IconButton size="small" title={tooltip} onClick={onClick}>
            {icon}
          </IconButton>
        ) : (
          progressIndicator
        )}
      </div>
    )
  },
  {
    width: "24px",
    height: "25px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
)
