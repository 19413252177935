import { IntlErrorCode } from "next-intl"
import logger from "~/util/logger"

/* istanbul ignore next */
export async function getTranslations(locale = "en") {
  const { translations } = await import(`./translations/${locale}`)

  return translations
}

export function onIntlError(error) {
  if (error.code === IntlErrorCode.MISSING_MESSAGE) {
    logger.warn(`⚠️ i18n MESSAGE MISSING: ${error.originalMessage}`)
  } else {
    logger.warn(`IntlError: code=${error.code}`)
    logger.error(error)
  }
}

export function getMessageFallback({ namespace, key, error }) {
  const path = [namespace, key].filter(part => part != null).join(".")

  if (error.code === IntlErrorCode.MISSING_MESSAGE) {
    return `${path} is not yet translated`
  } else {
    return `⚠️ Translation missing: ${path}`
  }
}
