import * as Sentry from "@sentry/nextjs"
import { isNotEmpty } from "~/util/index"

export function sentryEnabled({ inBrowser = true } = {}) {
  const NEXT_PUBLIC_SENTRY_DSN = process?.env?.NEXT_PUBLIC_SENTRY_DSN
  const NEXT_PUBLIC_SENTRY_ENV = process?.env?.NEXT_PUBLIC_SENTRY_ENV
  const SENTRY_ORG = process?.env?.SENTRY_ORG
  const SENTRY_PROJECT = process?.env?.SENTRY_PROJECT
  const VERCEL_GIT_COMMIT_SHA = process?.env?.VERCEL_GIT_COMMIT_SHA
  const SENTRY_AUTH_TOKEN = process?.env?.SENTRY_AUTH_TOKEN

  return inBrowser
    ? isNotEmpty(NEXT_PUBLIC_SENTRY_DSN) && isNotEmpty(NEXT_PUBLIC_SENTRY_ENV)
    : isNotEmpty(NEXT_PUBLIC_SENTRY_DSN) &&
        isNotEmpty(SENTRY_ORG) &&
        isNotEmpty(SENTRY_PROJECT) &&
        isNotEmpty(VERCEL_GIT_COMMIT_SHA) &&
        isNotEmpty(SENTRY_AUTH_TOKEN)
}

export function initSentry({ inBrowser = true } = {}) {
  const NEXT_PUBLIC_SENTRY_DSN = process?.env?.NEXT_PUBLIC_SENTRY_DSN
  const NEXT_PUBLIC_SENTRY_ENV = process?.env?.NEXT_PUBLIC_SENTRY_ENV
  const VERCEL_GIT_COMMIT_SHA = process?.env?.VERCEL_GIT_COMMIT_SHA

  if (sentryEnabled({ inBrowser })) {
    const integrations = inBrowser
      ? [
          new Sentry.Replay({
            maskAllText: false,
            blockAllMedia: false,
          }),
        ]
      : []
    const replayConfig = inBrowser
      ? { replaysSessionSampleRate: 0, replaysOnErrorSampleRate: 1.0 }
      : {}

    Sentry.init({
      dsn: NEXT_PUBLIC_SENTRY_DSN,
      release: VERCEL_GIT_COMMIT_SHA,
      environment: NEXT_PUBLIC_SENTRY_ENV,
      integrations,
      tracesSampleRate: 0.1,
      ...replayConfig,
      // Note: if you want to override the automatic release value, do not set a
      // `release` value here - use the environment variable `SENTRY_RELEASE`, so
      // that it will also get attached to your source maps

      // See for more info: https://docs.sentry.io/clients/javascript/tips/
      ignoreErrors: [
        // Random plugins/extensions
        "top.GLOBALS",
        "Extension context invalidated.",
      ],
      denyUrls: [
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
        // Google Adsense
        /pagead\/js/i,
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
      ],
    })
  }
}
