import { TrackingApi } from "~/services/aoeu"
import {
  TrackingPayloadType,
  TRACKING_EVENT_TYPES,
  UserDataPayload,
} from "~/services/aoeu/models"
import logger from "~/util/logger"

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _hsq?: (
      | [string, string | Record<string, string | number | undefined>]
      | [string]
    )[]
    _aoeuDownloadEvents?: (() => void)[]
    // storing timer for timeout refs on the DOM
    _hsqTimer?: NodeJS.Timeout
    _aoeuTimer?: NodeJS.Timeout
  }
}

export const hubspotTracking = (url: string): void => {
  /* Since multiple routers are live in the app at any one time, we need to make sure
     only the last one's timeout callback is allowed to fire. Each router instance
     clears the timer of the previous one. */
  window._hsqTimer && clearTimeout(window._hsqTimer)
  window._hsqTimer = setTimeout(() => {
    window._hsq = window._hsq ?? []
    window._hsq.push(["setPath", url])
    window._hsq.push(["trackPageView"])
    // The router never cedes control flow, (all registered callbacks execute consecutively
    // without forfeiting the thread), so we can use a very timeout and be sure
    // that there are no race issues.
  }, 100)
}

export const genPageViewTracker = (
  userId?: string | null,
  caller = "pageViewTracker",
  eventPayload?: Partial<TrackingPayloadType>,
) =>
  function (): void {
    if (!userId) return
    window._aoeuTimer && clearTimeout(window._aoeuTimer)

    window._aoeuTimer = setTimeout(() => {
      const trackingApi = new TrackingApi()
      const event = {
        event_description: document.title,
        event_page_id: window.location.href,
        event_type: TRACKING_EVENT_TYPES.VIEW,
        event_time: new Date().toISOString(),
        user_id: userId,
        ...eventPayload,
      }
      window.aptrinsic?.("event", TRACKING_EVENT_TYPES.VIEW, event)
      trackingApi.trackEvent(event).catch(e => {
        logger
          .withScope({
            tags: {
              caller,
              ...event,
            },
          })
          .error(e)
      })
      // prevent tracking calls twice in rapid succession
    }, 100)
  }

/**
 * Identifies a user in HubSpot using the provided user data.
 *
 * This function extracts the primary email and user ID from the user data and uses the HubSpot
 * Tracking Code API to identify the user. If the email is not found, the function
 * returns early without making any API calls.
 *
 * @param userData - The user data payload containing user information. It can be null.
 *
 * @see {@link https://developers.hubspot.com/beta-docs/reference/api/analytics-and-events/tracking-code#tracking-code-api-overview|HubSpot Tracking Code API Overview}
 */
export const identifyUserInHubspot = (
  userData: UserDataPayload | null,
): void => {
  const _hsq = (window._hsq = window._hsq || [])

  const orgId = userData?.edges?.organization?.id

  const email = userData?.edges?.emails.find(
    email => email.id === userData.primaryEmailId,
  )?.email

  if (!email) {
    return
  }

  _hsq.push([
    "identify",
    {
      id: userData.id,
      hs_object_id: userData.hubspotId,
      email,
      aoeu_org_id: orgId,
    },
  ])
}
