import * as Sentry from "@sentry/nextjs"
import jwt from "jsonwebtoken"
import _ from "lodash"
import { pages } from "@/urls"
import { FEATURES, STUDENT_TYPE, TIME } from "~/enums"
import { LoadError } from "~/errors/auth-error"
import { HttpRequestError } from "~/errors/http-request-error"
import { ApiClient } from "~/services/aoeu"
import {
  EntitlementPayload,
  Feature,
  OrganizationPayload,
  ProfileDataPayload,
  Role,
  Trait,
  UserDataPayload,
  UserTraitsPayload,
} from "~/services/aoeu/models"
import {
  getNewAuthenticationRefreshDelay,
  isNotEmpty,
  isValidDate,
} from "~/util"
import { clone } from "~/util/clone"
import { partition } from "~/util/functional"
import logger from "~/util/logger"
import { isLoginExempt, ssRouter } from "~/util/routing"
import { getStudentType } from "./util/student-type"

function base64Decode(base64EncodedString: string) {
  const buffer = Buffer.from(base64EncodedString, "base64")
  return buffer.toString("utf-8")
}

export function parseCookie(cookie: string): {
  userId?: string
  expiration?: Date
  isExpired: boolean
} {
  const { userId, exp } = JSON.parse(base64Decode(cookie.split(".")[1]))

  const expiration = new Date(exp * 1000)
  return {
    userId,
    expiration,
    isExpired: new Date(Date.now()) >= expiration,
  }
}

type TokenBase = {
  exp: number
  nbf: number
  iat: number
  iss: string
  aud: string
  tokenType?: TokenType
  user_id?: string
  feature_service_id?: string
  userId?: string
  featureServiceId?: string
}

type Token = Omit<TokenBase, "user_id" | "feature_service_id"> & {
  userId: string
  featureServiceId: string
  tokenType: TokenType
  _string: string
}

type TokenType = "refresh" | "access" | null

// eslint-disable-next-line complexity
export function decodeToken(token: string): {
  token: Token | null
  type: TokenType
} {
  const _token = (jwt.decode(token) ?? {}) as TokenBase
  const __token = {
    ..._token,
    userId: _token.user_id ?? _token.userId ?? "",
    featureServiceId:
      _token.feature_service_id ?? _token.featureServiceId ?? "",
    tokenType: <TokenType>(
      (_token.tokenType === "refresh" ? "refresh" : "access")
    ),
    _string: token,
  }
  if (__token?.exp && (!__token.userId || !__token.featureServiceId)) {
    if (!__token.userId) {
      logger.warn("valid accessToken does not have userId property")
    } else {
      logger.warn("valid accessToken does not have featureServiceId property")
    }
  }

  return {
    token: __token,
    type: "access",
  }
}

export function isTokenExpired(token: Token): boolean {
  try {
    const { exp } = token

    if (isNotEmpty(exp)) {
      const tokenExpiration = new Date((exp as number) * 1000)
      if (!isValidDate(tokenExpiration)) return true
      return Date.now() >= tokenExpiration.getTime()
    }

    return true
  } catch (err) {
    return true
  }
}

const defaultAuthState = {
  loggingIn: false,
  loggedOut: false,
  resettingPassword: false,
  resetPassword: false,
  email: "",
  rememberMe: false,
  authenticated: false,
  accessToken: null,
  returnTo: null,
  refreshTime: null,
  error: null,
}

const defaultUserState = {
  loading: false,
  error: null,
  emailVerificationPending: false,
  data: {
    id: null,
    authProfileID: null,
    primaryEmailId: null,
    primaryEmail: null,
    firstName: null,
    lastName: null,
    emailAddresses: [],
    studentType: STUDENT_TYPE.NONE,
    campusCafeId: null,
    degreeSeekingStatus: null,
    underGradDegreeConfirmed: null,
    wordpressId: null,
    hubspotId: null,
    hubspotPrimaryCompanyId: null,
    createdAt: null,
    updatedAt: null,
    edges: {
      entitlement: [],
    },
  },
  profile: {
    loading: false,
    error: null,
    data: {
      id: null,
      teachingRole: null,
      gradesTaught: null,
      highestEducationAchieved: null,
      stateStandard: null,
      streetAddress: null,
      streetAddress2: null,
      stripeCustomerId: null,
      cityTown: null,
      stateRegion: null,
      postalCode: null,
      country: null,
      phoneNumber: null,
      dateOfBirth: null,
      schoolDistrictName: null,
      createdAt: null,
      updatedAt: null,
    },
  },
  roles: [] as Role[],
}

const defaultFeatures = Object.values(FEATURES).filter(
  f => f.startsWith("@") && f !== FEATURES.MAINTENANCE_MODE,
)

type StoreUser = {
  loading: false
  error: boolean
  data: Omit<
    UserDataPayload,
    | "primaryEmailId"
    | "hubspotId"
    | "hubspotPrimaryCompanyId"
    | "createdAt"
    | "lastLoggedIn"
    | "updatedAt"
  > & {
    primaryEmail?: string
    emailAddresses: {
      id: number
      email: string
      isPrimary: boolean
      isVerified: boolean
    }[]
  }
  roles: Role[]
  profile: StoreProfile
}

type StoreProfile = {
  loading: boolean
  error: boolean | null
  data: ProfileDataPayload | null
}

type StoreAuth = {
  loggingIn: false
  loggedOut: false
  resettingPassword: boolean
  resetPassword: boolean
  email: string
  rememberMe: boolean
  authenticated: boolean
  accessToken: string | null
  returnTo: string | null
  refreshTime: Date | null
  error: boolean | null
}

type AuthData = StoreAuth | typeof defaultAuthState
type UserData = StoreUser | typeof defaultUserState
type FlagsmithData = UserTraitsPayload

type ServerSideEssentials = {
  accessToken: Token
  userData: UserDataPayload | null
  userError: boolean
  profileData: ProfileDataPayload | null
  profileError: boolean
  flagData: UserTraitsPayload | null
  flagError: boolean
  loaded: boolean
}
type LoadSubscriber =
  | ((essentials: ServerSideEssentials) => unknown)
  | ((essentials: ServerSideEssentials) => Promise<unknown>)

/** *
 * For use in Server-Side functions such as getServerSideProps and getInitialProps to determine
 * whether a user is authenticated and to load user and profile data.
 */
export class ServerSideAuth {
  private _accessToken: Token | null = null

  private _client: ApiClient | null = null

  private _loaded = false

  private readonly _deadToken: Token = {
    exp: 0,
    nbf: 0,
    iat: 0,
    iss: "",
    aud: "",
    userId: "",
    featureServiceId: "",
    tokenType: null,
    _string: "",
  }

  userData: UserDataPayload | null = null

  profileData: ProfileDataPayload | null = null

  flagData: UserTraitsPayload | null = null

  userError = false

  profileError = false

  flagError = false

  private subscribers: Record<string, LoadSubscriber> = {}

  private _subscriberOrder: string[] = []

  /*
   * Subscribe a callback function to be called whenever data is loaded. Returns a boolean indicating
   * whether the subscription needed to be added. */
  subscribe(loadCallback: LoadSubscriber, id: string | number): boolean {
    const _id = String(id)
    // eslint-disable-next-line no-prototype-builtins
    const alreadySubscribed = this.subscribers.hasOwnProperty(_id)
    if (!alreadySubscribed) {
      this._subscriberOrder.push(_id)
      this.subscribers[_id] = loadCallback
    }
    return !alreadySubscribed
  }

  /*
   * Un-subscribe the callback function corresponding to the given id. Returns a boolean indicating
   * whether the subscription existed beforehand.*/
  unsubscribe(id: string | number): boolean {
    const _id = String(id)
    // eslint-disable-next-line no-prototype-builtins
    if (!this.subscribers.hasOwnProperty(_id)) return false
    delete this.subscribers[_id]
    this._subscriberOrder.splice(this._subscriberOrder.indexOf(_id), 1)
    return true
  }

  /*
   * Execute all subscribers, ensuring the first one inserted goes last */
  async loadCallback(): Promise<unknown[]> {
    if (!this._subscriberOrder.length) return []
    const serialized = this.serialize()
    const [firstSubscriberId, ...subscriberIds] = this._subscriberOrder
    const results = await Promise.all(
      subscriberIds
        .reverse()
        .map(id => this.subscribers[id](clone(serialized))),
    )
    results.push(await this.subscribers[firstSubscriberId](clone(serialized)))
    return results
  }

  get accessToken(): Token {
    if (!this._accessToken) return this._deadToken
    return this._accessToken as Token
  }

  get client(): ApiClient {
    if (!this._accessToken)
      throw new LoadError(
        "Attempted to access client object with unauthenticated Auth instance",
      )
    return this._client as ApiClient
  }

  get sessionIsActive(): boolean {
    return Boolean(this._client && !isTokenExpired(this.accessToken))
  }

  get auth(): AuthData {
    if (!this._loaded)
      throw new LoadError("Attempted access of auth data before load")
    if (!this.userData || !this.sessionIsActive) return defaultAuthState
    const { primaryEmailId } = this.userData
    return {
      loggingIn: false,
      loggedOut: false,
      resettingPassword: false,
      resetPassword: false,
      email:
        (this.userData.edges?.emails ?? []).find(e => e.id === primaryEmailId)
          ?.email ?? "",
      rememberMe: false,
      authenticated: true,
      accessToken: "token",
      returnTo: null,
      refreshTime: getNewAuthenticationRefreshDelay(),
      error: null,
    }
  }

  get user(): UserData {
    if (!this._loaded) {
      throw new LoadError("Attempted access of auth data before load")
    }

    if (!this.userData || !this.sessionIsActive) {
      return defaultUserState
    }

    const {
      id,
      firstName,
      lastName,
      primaryEmailId,
      underGradDegreeConfirmed,
      wordpressId,
      campusCafeId,
      degreeSeekingStatus,
      edges: { emails, roles } = { emails: [], roles: [] },
    } = this.userData

    const emailAddresses = (emails ?? []).map(
      ({ id: emailAddressId, email, verifiedAt }) => {
        return {
          id: emailAddressId,
          email,
          isPrimary: emailAddressId === primaryEmailId,
          isVerified: isNotEmpty(verifiedAt),
        }
      },
    )

    const primaryEmail =
      emailAddresses.length === 1
        ? emailAddresses[0].email
        : emailAddresses.find(email => email.isPrimary)?.email

    const studentType = getStudentType(campusCafeId, degreeSeekingStatus)

    return {
      loading: false,
      error: this.userError,
      data: {
        ...this.userData,
        id,
        firstName,
        lastName,
        primaryEmail,
        emailAddresses,
        studentType,
        underGradDegreeConfirmed,
        wordpressId,
      },
      roles: (roles ?? []).map(({ role }) => role),
      profile: {
        loading: false,
        error: this.profileError,
        data: this.profileData,
      },
    }
  }

  get flags(): FlagsmithData {
    if (!this._loaded) {
      throw new LoadError("Attempted access of feature data before load")
    }

    if (!this.flagData || !this.sessionIsActive) {
      return { flags: [], traits: [] }
    }

    return clone(this.flagData)
  }

  get traits(): Trait[] {
    if (!this.userData || !this.sessionIsActive) return []
    const { primaryEmailId, edges: { emails = [] } = {} } = this.userData
    const [primary, alternate] = partition(
      emails ?? [],
      email => email.id === primaryEmailId,
    )
    return [
      { trait_key: `primary-email-address`, trait_value: primary[0]?.email },
      {
        trait_key: `alternate-email-address-1`,
        trait_value: alternate[0]?.email,
      },
      {
        trait_key: "teaching-role",
        trait_value: this.profileData?.teachingRole,
      },
      {
        trait_key: "highest-education-achieved",
        trait_value: this.profileData?.highestEducationAchieved,
      },
      {
        trait_key: "state-standard",
        trait_value: this.profileData?.stateStandard,
      },
      /* If flagsmith receives null values for a trait, it unsets the trait. */
    ].map(trait => ({ ...trait, trait_value: trait.trait_value ?? null }))
  }

  get features(): Set<Feature> {
    if (!this._loaded)
      throw new LoadError("Attempted access of auth data before load")
    if (this.userError || this.profileError || this.flagError)
      return new Set(defaultFeatures)
    return this.flagData?.flags?.length
      ? new Set(
          this.flagData.flags
            .filter(flag => flag.enabled)
            .map(flag => flag.feature.name),
        )
      : new Set(defaultFeatures)
  }

  get org(): Optional<OrganizationPayload> {
    return this.userData?.edges?.organization
  }

  get entitlements(): EntitlementPayload[] {
    return this.userData?.edges?.entitlement ?? []
  }

  get featureServiceId(): string | null {
    const { featureServiceId } = this.accessToken
    if (featureServiceId) return featureServiceId
    const {
      data: { id, firstName, lastName },
    } = this.user
    if (id && firstName && lastName) {
      return `${firstName} ${lastName} (${id})`
    }
    return null
  }

  get authenticated(): boolean {
    return this.auth.authenticated
  }

  isDistrictAdminOf(org?: OrganizationPayload): boolean {
    return (
      !!(org?.districtAdmin && this.userData?.id) &&
      org.districtAdmin === this.userData?.id
    )
  }

  get isDistrictAdmin(): boolean {
    return !!this.org && this.isDistrictAdminOf(this.org)
  }

  guardPath(pathname: string, query?: Record<string, string>): void {
    if (!this.authenticated && !isLoginExempt(pathname)) {
      ssRouter.push(pages.user.login, {
        returnTo: pathname,
        ...query,
      })
    }
  }

  /**
   * Reset internal state back to default, except this._loaded = true to indicate a data load is
   * not necessary.
   * @param callback: a function to be executed after a successful logout
   * @param useClient: ApiClient instance to use once the logout is complete
   */
  async logout<T>(
    callback?: () => Promise<T>,
    useClient: ApiClient | null = null,
  ): Promise<void> {
    if (this.sessionIsActive) {
      try {
        await this.client.logout()
        this._clearUserSentryContext()
      } catch (err) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        logger.withScope({ tags: { caller: "logout" } }).error(err)
      }
    }
    this._accessToken = this._deadToken
    this._loaded = true
    this.userData = null
    this.userError = false
    this.profileData = null
    this.profileError = false
    // call flagsmith to get feature flags for unauthenticated users
    await this.load(true)
    this._client = useClient
    callback && (await callback())
  }

  /**
   * Reset internal state back to default and authenticate the client with the provided token. A
   * data load will be necessary before auth and user data can be accessed.
   * @param accessToken: JWT web token
   * @param client = null: ApiClient instance, used to retrieve user and profile data
   */
  login(accessToken: string, client: ApiClient | null = null): void {
    this.userData = null
    this.userError = false
    this.profileData = null
    this.profileError = false
    this.flagData = null
    this.flagError = false
    this._loaded = false
    const { token, type } = decodeToken(accessToken)
    this._accessToken = type === "access" ? (token as Token) : this._deadToken
    this._client =
      client ??
      new ApiClient({
        headers: {
          Cookie: `aoeu-session=${accessToken}`,
        },
      })
  }

  constructor(accessToken: string, client: ApiClient | null = null) {
    this.login(accessToken, client)
  }

  serialize(): ServerSideEssentials {
    return {
      accessToken: this.accessToken,
      userData: this.userData,
      userError: this.userError,
      profileData: this.profileData,
      profileError: this.profileError,
      flagData: this.flagData,
      flagError: this.flagError,
      loaded: this._loaded,
    }
  }

  static deserialize({
    accessToken,
    userData,
    userError,
    profileData,
    profileError,
    flagData,
    flagError,
    loaded,
  }: ServerSideEssentials): ServerSideAuth {
    const _auth = new this(accessToken._string)
    _auth.userData = userData
    _auth.userError = userError
    _auth.profileData = profileData
    _auth.profileError = profileError
    _auth.flagData = flagData
    _auth.flagError = flagError
    _auth._loaded = loaded
    return _auth
  }

  protected async _loadProfile(
    _data?: ProfileDataPayload | null,
  ): Promise<{ data: ProfileDataPayload | null; error?: unknown }> {
    try {
      if (_data !== undefined) return { data: _data }
      logger.debug("loading profile...")
      const { data } = await this.client.getUserProfile(this.accessToken.userId)
      logger.debug("loaded profile")
      return {
        data,
      }
    } catch (err) {
      return {
        data: null,
        error:
          err instanceof HttpRequestError && err.status === 404
            ? undefined
            : err,
      }
    }
  }

  protected _clearUserSentryContext(): void {
    Sentry.setUser(null)
  }

  protected _setUserSentryContext(data?: UserDataPayload | null): void {
    if (data) {
      const { id, firstName, lastName, primaryEmailId } = data

      const email =
        (data.edges?.emails ?? []).find(e => e.id === primaryEmailId)?.email ??
        ""
      const username = `${firstName} ${lastName}`

      Sentry.setUser({
        id,
        username,
        email,
      })
    }
  }

  protected async _loadUser(
    _data?: UserDataPayload | null,
  ): Promise<{ data: UserDataPayload | null; error?: unknown }> {
    try {
      if (_data !== undefined) return { data: _data }
      logger.debug("loading user...")
      const data = <UserDataPayload>(
        (await this.client.getUserById(this.accessToken.userId)).data
      )
      logger.debug("loaded user")
      return {
        data,
      }
    } catch (err) {
      return {
        data: null,
        error: err,
      }
    }
  }

  protected async _loadFeatures(
    _data?: UserTraitsPayload | null,
  ): Promise<{ data: UserTraitsPayload | null; error?: unknown }> {
    if (_data !== undefined) return { data: _data }
    /* At this point, featureServiceId can only safely be pulled off of the accessToken
      because user data is not available to compute it before a load. */
    const { featureServiceId } = this.accessToken
    try {
      if (featureServiceId && this.sessionIsActive) {
        logger.debug("loading user traits...")
        const { flags, traits } = (
          await this.client.getUserTraits({ featureServiceId })
        ).data
        return { data: { flags, traits } }
      } else {
        logger.debug("loading default flags...")
        const flags = (await this.client.getFlags()).data
        return { data: { flags, traits: [] } }
      }
    } catch (err) {
      return {
        data: null,
        error: err,
      }
    }
  }

  protected async _setTraits(traits: Trait[]): Promise<unknown> {
    const { featureServiceId } = this
    if (!featureServiceId) {
      return "featureServiceId is not set and could not be computed."
    }
    try {
      if (this.sessionIsActive) {
        logger.debug("setting traits...")
        await this.client.setUserTraits({ featureServiceId, traits })
      }
    } catch (err) {
      return err
    }
    return null
  }

  protected _traitsEqual(t1: Trait[], t2: Trait[]): boolean {
    const objectify = (t: Trait[]) =>
      t.reduce((accum, trait) => {
        /* If the trait value is null, we don't consider it as part of the comparison
          so null values can be passed along without triggering unnecessary updates. */
        if (trait.trait_value !== null)
          accum[trait.trait_key] = trait.trait_value
        return accum
      }, {} as Record<string, string>)
    return _.isEqual(objectify(t1), objectify(t2))
  }

  /**
   * This method loads the user, profile, and feature data indicated by the userId and featureServiceId
   * in the JWT access token. The results of the load are cached and persisted between client-side
   * page navigations. The parameters allow behavior to be controlled by the caller.
   *
   * By default, this.load will force auth data to be loaded (even if the ServerSideAuth instance
   * has already performed a load, indicated by this._loaded = true). See the `force` parameter
   * description for details on overriding default behavior.
   *
   * @param force = {}: Whether to force the client to load new data. can be either a boolean or an
   *  object containing the desired user/profile data.
   * @param raise = true: Whether to raise errors encountered during a load. if set to false, any
   *  errors surfaced as a return value. User loading errors take precedence.
   */
  /* I consider this to be an acceptable amount of complexity for what the function achieves;
   * complexity will be managed by explicitly documenting intended behavior. */
  // eslint-disable-next-line complexity
  async load(
    force:
      | {
          // each item can be set to empty by passing null
          user?: UserDataPayload | null
          profile?: ProfileDataPayload | null
          features?: UserTraitsPayload | null
        }
      | boolean = {},
    raise = true,
  ): Promise<unknown> {
    let loadError = null
    /* Using nested try blocks here to make it practically impossible to throw errors
     * from this.load if !raise */
    try {
      // attempt the actual loading
      try {
        this.userError = false
        this.profileError = false
        this.flagError = false
        // ensure user, profile, and features defaults are available, even if undefined
        const { user, profile, features } =
          typeof force === "boolean"
            ? { user: undefined, profile: undefined, features: undefined }
            : { ...force }
        if (!this.sessionIsActive) {
          this.userData && (await this.logout(undefined, this.client))
          const { data, error } = await this._loadFeatures(features)
          this.flagData = { flags: data?.flags ?? [], traits: [] }
          this.flagError = !!error
          if (error) loadError = error
        } else if (force || !this._loaded) {
          /* If we are forcing a load or this instance has not yet been loaded, we do perform a load
          and cache the results */
          const [userPayload, profilePayload, featurePayload] =
            await Promise.all([
              this._loadUser(user),
              this._loadProfile(profile),
              this._loadFeatures(features),
            ])
          {
            const { data, error } = userPayload
            this.userData = data
            this.userError = !!error
            this._setUserSentryContext(data)
            if (this.userError) loadError = error
          }
          {
            const { data, error } = profilePayload
            this.profileData = data
            this.profileError = !!error
            // want to ensure the user error is emitted
            if (!loadError && this.profileError) loadError = error
          }
          {
            const { data, error } = featurePayload
            this.flagData = {
              flags: data?.flags ?? [],
              traits: data?.traits ?? [],
            }
            this.flagError = !!error
            // want to ensure preceding errors are emitted
            if (!loadError && this.flagError) loadError = error
            // if the user's traits have changed, we submit the new traits to FlagSmith
            const retrievedTraits = data?.traits ?? []
            const computedTraits = this.traits
            if (!this.flagError && !this.userError && computedTraits.length) {
              if (!this._traitsEqual(retrievedTraits, computedTraits)) {
                const err = await this._setTraits(computedTraits)
                // No need to throw for failure here; the user's day should continue on as usual.
                if (err)
                  logger
                    .withScope({ tags: { caller: "auth._setTraits" } })
                    .error(err)
              }
            }
          }
        }
        // block always executed last, even after return or throw in try/except
      } finally {
        // consider data getters to be ready
        this._loaded = true
        await this.loadCallback()
      }
      // catch any unexpected error here to be handled as the caller sees fit
    } catch (err) {
      loadError = err
      // any loadError results in an un-authenticated session
    } finally {
      if (loadError) {
        this.userError = true
        this._accessToken = this._deadToken
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    if (raise && loadError) throw loadError
    return loadError
  }

  /*
   * This method simulates a partial update to the user, profile, and feature state. */
  async patch({
    user = undefined,
    profile = undefined,
    features = undefined,
  }: {
    user?: UserDataPayload | null
    profile?: ProfileDataPayload | null
    features?: UserTraitsPayload | null
  }): Promise<void> {
    await this.load({
      user: user !== undefined ? user : this.userData,
      profile: profile !== undefined ? profile : this.profileData,
      features: features !== undefined ? features : this.flagData,
    })
  }
}

export function generateToken(userId: string | null = null): string {
  const [issued, expiration] = (() => {
    const _issued = Date.now()
    const _expiration = _issued + TIME.ONE_HOUR
    return [_issued, _expiration].map(v => Math.floor(v / 1000))
  })()
  return userId
    ? jwt.sign(
        {
          user_id: userId,
          feature_service_id: `FirstName LastName (${userId})`,
          districtId: null,
          roleType: "User",
          tokenType: "access",
          exp: expiration,
          nbf: issued,
          iat: issued,
          iss: "urn:aoeu",
          aud: "urn:aoeu:user",
        },
        "secret",
      )
    : jwt.sign(
        {
          user_id: "unauthenticated-user",
          feature_service_id: null,
          districtId: null,
          roleType: "User",
          tokenType: "access",
          exp: 0,
          nbf: 0,
          iat: 0,
          iss: "urn:aoeu",
          aud: "urn:aoeu:user",
        },
        "secret",
      )
}

export function generateAuth(
  accessToken: string,
  {
    userData,
    profileData,
    flagData,
  }: Partial<Pick<ServerSideAuth, "userData" | "profileData" | "flagData">> = {
    userData: null,
    profileData: null,
    flagData: null,
  },
  client: ApiClient | null = null,
): ServerSideAuth {
  const auth = new ServerSideAuth(accessToken, client)
  userData && (auth.userData = userData)
  profileData && (auth.profileData = profileData)
  flagData && (auth.flagData = flagData)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  auth._loaded = true
  return auth
}
