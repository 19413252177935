export const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID

export function pageView(url) {
  if (GTM_ID) {
    window.dataLayer.push({
      event: "pageview",
      page: url,
    })
  }
}

// Signup, Login, and Purchase will need
// unique functions to better integrate with GA
export function signUp(url, method) {
  if (GTM_ID) {
    window.dataLayer.push({
      event: "sign_up",
      page: url,
      method: method,
    })
  }
}

export function formSubmission(url, formId) {
  if (GTM_ID) {
    window.dataLayer.push({
      event: "formsubmission",
      page: url,
      form: formId,
    })
  }
}
