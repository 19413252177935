import { useQuery } from "@tanstack/react-query"
import { addDays, isAfter, isBefore } from "date-fns"
import { getUserEntitlementStatus, isActive } from "admin/utils"
import {
  loadProductPrices,
  ParsedPrice,
} from "marketplace/loaders/product-prices"
import { useAuth } from "~/hooks/use-auth"
import { NOWConfPayloadType } from "~/services/aoeu/models"

export const NOW_CONF_CONFIG_ERRORS = {
  MISSING_NOW_CONFERENCE_DATA: "No NOW Conference data could be found",
  MISSING_PRICE_DATA: "No Stripe price data could be found",
  MISSING_PRICE: "Missing Stripe price data required to support NOW Conference",
}

export type UseNowConferencesReturn = {
  isLoading: boolean
  error: unknown
  userHasCurrentNow?: boolean
  currentNowConf?: Pick<
    NOWConfPayloadType,
    "productName" | "eventDate" | "eventEndDate"
  > &
    AvailabilityData & {
      stripeData: ParsedPrice
    }
}

/**
 * Determine current NOW conference and whether the current user has matching entitlements.
 */
export function useNowConferences(): UseNowConferencesReturn {
  const { entitlements, client } = useAuth()

  const {
    isLoading: pricesLoading,
    error: pricesError,
    data: productPrices,
  } = useQuery({
    queryKey: ["prices"],
    queryFn: () => loadProductPrices({ api: client }),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  })

  const {
    isLoading: conferencesLoading,
    error: conferencesError,
    data: conferences,
  } = useQuery({
    queryKey: ["conferences"],
    queryFn: () => client.getAllNowConferences(),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  })

  const isLoading = conferencesLoading || pricesLoading
  const pricesAvailable = Object.keys(productPrices ?? {}).length !== 0
  const conferencesAvailable = conferences?.data?.length
  const error = pricesError ?? conferencesError

  if (isLoading || error) {
    return {
      isLoading,
      error,
    }
  }

  if (!pricesAvailable) {
    return {
      isLoading,
      error: new Error(NOW_CONF_CONFIG_ERRORS.MISSING_PRICE_DATA),
    }
  }

  if (conferencesAvailable) {
    const currentNowConf = getCurrentNOWConference(conferences.data)
    if (!currentNowConf) {
      return {
        isLoading,
        error,
        userHasCurrentNow: false,
        currentNowConf,
      }
    }
    const productPrice = Object.values(productPrices!).find(
      price =>
        price.productName === getProductLongName(currentNowConf.productName),
    )
    if (!productPrice) {
      return {
        isLoading,
        error: new Error(NOW_CONF_CONFIG_ERRORS.MISSING_PRICE),
      }
    }
    return {
      isLoading,
      error,
      userHasCurrentNow: !!getUserEntitlementStatus(
        entitlements,
        ent => isActive(ent) && ent.productName === currentNowConf?.productName,
      ).now,
      currentNowConf: {
        ...currentNowConf,
        ...computeNowConfAvailability(currentNowConf),
        stripeData: productPrice,
      },
    }
  }
  return {
    isLoading,
    error: conferencesAvailable
      ? error
      : new Error(NOW_CONF_CONFIG_ERRORS.MISSING_NOW_CONFERENCE_DATA),
  }
}

/**
 * Given a product_name matching ^now_(winter|summer)_[0-9][0-9]$,
 * return the season and 4 digit year. Otherwise, return undefined
 * @param {string} productName
 * @return {Optional<{year: number, season: "winter" | "summer"}>}
 */
export function getProductAttributes(
  productName: string,
): Optional<{ year: number; season: "winter" | "summer" }> {
  const [, season, shortYear] = productName.match(
    /^now_(summer|winter)_(\d{2})$/,
  ) ?? [null, null, null]
  if (!season) return undefined
  const year = parseInt(shortYear!) + 2000
  return { season: season as "winter" | "summer", year }
}

export function getProductLongName(productName: string): string | undefined {
  const { season, year } = getProductAttributes(productName) ?? {}
  if (!season) return undefined
  return `${season[0].toUpperCase()}${season.slice(1)} ${year} NOW Conference`
}

type AvailabilityData = { afterPassAvailable: boolean; saleAvailable: boolean }

/**
 * Given a NOWConfPayloadType, determine based on the current date
 * whether it is for sale and whether its afterPass is available.
 * @param {string} eventDate
 * @param {string?} eventEndDate
 * @param {"scheduled" | "available" | "unavailable"} saleAvailability
 * @param {"scheduled" | "available" | "unavailable"} afterPassAvailability
 * @param {string?} saleScheduledStart
 * @param {string?} saleScheduledEnd
 * @param {string?} afterPassScheduledStart
 * @param {string?} afterPassScheduledEnd
 * @return {AvailabilityData}
 */
export function computeNowConfAvailability({
  eventDate,
  eventEndDate,
  // both afterPass and sale are scheduled by default for legacy compat
  saleAvailability = "scheduled",
  afterPassAvailability = "scheduled",
  // event can be sold as soon as it exists in the database
  saleScheduledStart = new Date(0).toISOString(),
  // sale ends once the event begins
  saleScheduledEnd = eventDate,
  // afterPass should be available right after the event ends
  afterPassScheduledStart = (eventEndDate
    ? new Date(eventEndDate)
    : // in case we don't know eventEndDate
      addDays(new Date(eventDate), 4)
  ).toISOString(),
  afterPassScheduledEnd = addDays(
    new Date(afterPassScheduledStart),
    365,
  ).toISOString(),
}: Pick<
  NOWConfPayloadType,
  | "eventDate"
  | "eventEndDate"
  | "saleAvailability"
  | "saleScheduledStart"
  | "saleScheduledEnd"
  | "afterPassAvailability"
  | "afterPassScheduledStart"
  | "afterPassScheduledEnd"
>): AvailabilityData {
  const now = new Date()
  const isBetween = (date: Date, start: Date, end: Date) =>
    isAfter(date, start) && isBefore(date, end)
  let saleAvailable = false
  switch (saleAvailability) {
    case "available":
    case "unavailable":
      saleAvailable = saleAvailability === "available"
      break
    case "scheduled":
      saleAvailable = isBetween(
        now,
        new Date(saleScheduledStart),
        new Date(saleScheduledEnd),
      )
  }
  let afterPassAvailable = false
  switch (afterPassAvailability) {
    case "available":
    case "unavailable":
      afterPassAvailable = afterPassAvailability === "available"
      break
    case "scheduled":
      afterPassAvailable = isBetween(
        now,
        new Date(afterPassScheduledStart),
        new Date(afterPassScheduledEnd),
      )
  }
  return { afterPassAvailable, saleAvailable }
}

/**
 * Given a list of NOWConfPayloadType objects,
 * return the oldest (by eventDate) NOW conference which is currently for sale
 * @param {NOWConfPayloadType[]} conferences
 * @return {Optional<NOWConfPayloadType>}
 */
export function getCurrentNOWConference<T extends NOWConfPayloadType>(
  conferences: T[],
): Optional<T> {
  const currentDate = new Date()

  const isCurrentDatetimeInRange = (
    startDateStr?: string,
    endDateStr?: string,
  ) => {
    if (!startDateStr || !endDateStr) return false
    const startDate = new Date(startDateStr)
    const endDate = new Date(endDateStr)
    return currentDate >= startDate && currentDate <= endDate
  }

  // Find conference where the current date is within sale period
  const currentConferenceForSale = conferences.find(conf =>
    isCurrentDatetimeInRange(conf.saleScheduledStart, conf.saleScheduledEnd),
  )

  if (currentConferenceForSale) {
    return currentConferenceForSale
  } else {
    // Find conference where the current date in within range of the event dates
    const activeConference = conferences.find(conf =>
      isCurrentDatetimeInRange(conf.eventDate, conf.eventEndDate),
    )

    return activeConference
  }
}
