import { OptionsObject, SnackbarMessage, useSnackbar } from "notistack"
import { useMemo } from "react"

type MessageHandler = (
  message: SnackbarMessage,
  options?: OptionsObject,
) => void

export const useSnacks = (): {
  showError: MessageHandler
  showSuccess: MessageHandler
  showWarning: MessageHandler
  showInfo: MessageHandler
} => {
  const { enqueueSnackbar } = useSnackbar()
  return useMemo(
    () => ({
      showError: (message: SnackbarMessage, options?: OptionsObject) =>
        message && enqueueSnackbar(message, { variant: "error", ...options }),
      showSuccess: (message: SnackbarMessage, options?: OptionsObject) =>
        message && enqueueSnackbar(message, { variant: "success", ...options }),
      showWarning: (message: SnackbarMessage, options?: OptionsObject) =>
        message && enqueueSnackbar(message, { variant: "warning", ...options }),
      showInfo: (message: SnackbarMessage, options?: OptionsObject) =>
        message && enqueueSnackbar(message, { variant: "info", ...options }),
    }),
    [enqueueSnackbar],
  )
}

export default useSnacks
