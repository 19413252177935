import cookie from "cookie"
import { isNotEmpty } from "~/util/index"
import logger from "~/util/logger"

const standardCookieKeys = ["Domain", "expires", "Max-Age", "Path", "SameSite"]

/**
 * Split cookie entries on comma, unless the comma is part of a date string.
 * This behavior can be achieved more cleanly with a negative lookbehind RegExp, but
 * Safari doesn't support them, and they are not polyfilled by next.js. You have been warned. */
function cookieSplit(string) {
  const noSplit = /(Mon|Tue|Wed|Thu|Fri|Sat|Sun)/
  const split = string.split(/,\s*/)
  return string.split(/,\s*/).reduce((accum, match, idx) => {
    const prevMatch = split[idx - 1]
    const nextMatch = split[idx + 1]
    /* If this item matches our noSplit criteria and there is a nextMatch, add them to accum
     * as a single item. */
    if (match.match(noSplit) && nextMatch) {
      accum.push(`${match}, ${nextMatch}`)
    } else if (prevMatch && prevMatch.match(noSplit)) {
      /* If the previous item was a noSplit, then this match has already been included (because they
      were re-joined). */
    } else {
      accum.push(match)
    }
    return accum
  }, [])
}

export function extractCookiesFromHeader(cookieHeader) {
  if (!cookieHeader) return
  try {
    // split cookie entries on comma, unless the comma is part of a date string
    const cookies = cookieSplit(cookieHeader)
    return cookies.reduce((parsedCookies, _cookie) => {
      const currentCookie = cookie.parse(_cookie)

      const cookieKeys = Object.keys(currentCookie).filter(
        key => !standardCookieKeys.includes(key),
      )
      const cookieKey = cookieKeys[0]

      currentCookie.cookieKey = cookieKey
      currentCookie.cookieValue = currentCookie[cookieKey]
      delete currentCookie[cookieKey]
      parsedCookies.push(currentCookie)
      return parsedCookies
    }, [])
  } catch (err) {
    logger.error(err)
  }
}

export function buildCookieArray(foundCookies) {
  const newCookies = []

  foundCookies.forEach(currentCookie => {
    const newCookiesOptions = {
      domain: currentCookie.Domain,
      path: currentCookie.Path,
    }

    if (currentCookie.expires) {
      newCookiesOptions.expires = new Date(currentCookie.expires)
    }

    if (currentCookie["Max-Age"]) {
      newCookiesOptions.maxAge = currentCookie["Max-Age"]
    }

    if (currentCookie.SameSite) {
      newCookiesOptions.sameSite = currentCookie.SameSite
    }

    if (currentCookie.Path) {
      newCookiesOptions.path = currentCookie.Path
    }

    newCookies.push(
      cookie.serialize(currentCookie.cookieKey, currentCookie.cookieValue, {
        ...newCookiesOptions,
        secure: isNotEmpty(process.env.NEXT_PUBLIC_SECURE_COOKIE),
        httpOnly: isNotEmpty(process.env.NEXT_PUBLIC_HTTP_ONLY_COOKIE),
      }),
    )
  })

  return newCookies
}

export function getCSRFCookie() {
  if (typeof document === "undefined" || document?.cookie) {
    return null
  }

  const xsrfCookies = document.cookie
    .split(";")
    .map(c => c.trim())
    .filter(c => c.startsWith("csrftoken="))

  if (xsrfCookies.length === 0) {
    return null
  }
  return decodeURIComponent(xsrfCookies[0].split("=")[1])
}
