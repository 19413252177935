export class LoadError extends Error {
  constructor(message) {
    super(message)
    this.name = "LoadError"

    if (
      Error.captureStackTrace &&
      typeof Error.captureStackTrace === "function"
    ) {
      Error.captureStackTrace(this, LoadError)
    }
  }
}
