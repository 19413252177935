/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react"

/**
 * Wrapper around useState which allows partial updates. Useful when maintaining several
 * distinct states that can be updated independently or batched within a single render.
 *
 * @param initState initial state or callback providing initial state
 * @param callback callback to perform on state update
 */
export function usePartialState<T extends Record<string, any>>(
  initState: T | ((...args: any) => T),
  callback?: (a: T) => void,
): [T, (s: Partial<T>) => void, (reducer: Reducer<T>) => void] {
  const [state, setState] = useState<T>(initState)
  return [
    state,
    (partialState: Partial<T>) => {
      let newState
      setState(currentState => {
        newState = { ...currentState, ...partialState }
        return newState
      })
      callback && callback(newState as unknown as T)
    },
    (reducer: Reducer<T>) => {
      let newState
      setState(currentState => {
        newState = { ...currentState, ...reducer(currentState) }
        return newState
      })
      callback && callback(newState as unknown as T)
    },
  ]
}

export type Reducer<T> = (s: T) => Partial<T>
