import { pages } from "@/urls"
import { rstrip, strip, withWindow } from "~/util/index"

export function navigateTo<T extends string>(url: T | null): void {
  withWindow(window => {
    if (url) window.location.replace(url)
  })
}

interface Stringable {
  toString(): string
}

/**
 * Used as a replacement for next.js router object when server-side routing is required.
 */
export class ServerSideRouter {
  push(url: string | null, query: Record<string, Stringable> = {}): void {
    withWindow(() => {
      const { protocol, hostname, port } = window.location
      const base = `${protocol}//${hostname}${port ? ":" + port : ""}`
      const _url = url ? url : base
      const __url = _url.startsWith(base)
        ? new URL(_url)
        : new URL(`${base}/${strip(_url as string, "/")}/`)
      if (query) {
        Object.keys(query).forEach(key => {
          __url.searchParams.set(key, String(query[key]))
        })
      }
      window.location.assign(__url.toString())
    })
  }
}

export const ssRouter = new ServerSideRouter()

const loginExemptRoutes = new Set<string>(
  [
    pages.user.login,
    pages.user.social,
    pages.user.logout,
    pages.user.create,
    pages.user.error,
    pages.user.verifyEmail,
    pages.user.resendVerifyEmail,
    pages.user.unverifiedEmail,
    pages.termsAndConditions,
  ].map(r => rstrip(r, "/")),
)

const loginExemptPatterns = [
  /^\/(4|5)\d{2}\/?/, // any HTTP error page
  /^\/_error\/?.*/, // or the generic error page
  /^\/purchase\/.*/,
  /^\/api\/auth\/.*/,
  /^\/api\/redirect-legacy-wp-url(?:\?.*)?$/,
  /.*\.(png|jpe?g|svg|css|js)/,
]

export function isLoginExempt(path: string): boolean {
  return (
    loginExemptRoutes.has(path) ||
    loginExemptPatterns.some(pattern => pattern[Symbol.match](path))
  )
}
