import { ReactNode } from "react"
import { getProductAttributes } from "~/hooks/use-now-conferences"
import { ApiClient } from "~/services/aoeu"
import { EntitlementPayload, UserDataPayload } from "~/services/aoeu/models"
import { clientSide, isEmpty, isNotEmpty } from "~/util"
import logger from "~/util/logger"

/* Init Gainsight */
export function loadGainsightScript(): ReactNode {
  const tagKey = process.env.NEXT_PUBLIC_GAINSIGHT_TAG_KEY
  const gainsightEnabled = process.env.NEXT_PUBLIC_GAINSIGHT_ENABLED === "true"
  if (isNotEmpty(tagKey) && gainsightEnabled) {
    return (
      <script
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        strategy="afterInteractive" // strategy is a custom next.js attribute
        dangerouslySetInnerHTML={{
          __html: `
            (function(n,t,a,e,co){var i="aptrinsic";n[i]=n[i]||function(){
              (n[i].q=n[i].q||[]).push(arguments)},n[i].p=e;n[i].c=co;
            var r=t.createElement("script");r.async=!0,r.src=a+"?a="+e;
            var c=t.getElementsByTagName("script")[0];c.parentNode.insertBefore(r,c)
            })(window,document,"https://web-sdk.aptrinsic.com/api/aptrinsic.js","${tagKey}");
            `,
        }}
      />
    )
  }

  return null
}

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    aptrinsic: any
  }
}

function getUserEntitlementStatus(
  userId: string,
  productName: string,
  entitlement?: EntitlementPayload[],
) {
  if (entitlement) {
    for (let i = 0; i < entitlement.length; i++) {
      if (
        entitlement[i]?.productName == productName &&
        entitlement[i]?.productStatus.toLowerCase() == "activated"
      ) {
        const userUUID = userId
        const adminUUID = entitlement[i]?.entitlementAdmin
        return userUUID == adminUUID ? "individual" : "enterprise"
      }
    }
  }
  return "none"
}

function attributeNameFromProduct(productName: string): Optional<string> {
  const { season, year } = getProductAttributes(productName) ?? {}
  if (!season) return undefined
  return `NOW${season[0].toUpperCase()}${season.slice(1)}${year}Entitlement`
}

export async function sendDataToGainsight(
  client: ApiClient,
  userData: UserDataPayload | null,
): Promise<void> {
  if (clientSide(false)) return
  if (isEmpty(window.aptrinsic)) {
    if (process.env.NEXT_PUBLIC_GAINSIGHT_ENABLED === "true")
      logger.error("Gainsight error: Aptrinsic function is not available")
    return
  }
  if (isEmpty(userData) || !userData) {
    logger.error("Gainsight error: User data is empty")
    return
  }
  try {
    const { id, hubspotId, hubspotPrimaryCompanyId } = userData
    const emailObject = userData.edges?.emails.find(email => {
      return email.id == userData.primaryEmailId
    })
    const email = emailObject ? emailObject.email : undefined
    const { status, data: nowConferences } = await client.getAllNowConferences()
    if (status !== 200) {
      logger
        .withScope({ tags: { caller: "sendDataToGainsight" } })
        .error(`failed to retrieve NOW conferences with status ${status}`)
      return
    }
    const userEntitlementAttributes = nowConferences.reduce<
      Record<string, string>
    >(
      (accum, product) => {
        const attributeName = attributeNameFromProduct(product.productName)
        if (attributeName)
          accum[attributeName] = getUserEntitlementStatus(
            userData.id,
            product.productName,
            userData.edges?.entitlement,
          )
        return accum
      },
      {
        PROEntitlement: getUserEntitlementStatus(
          userData.id,
          "pro",
          userData.edges?.entitlement,
        ),
        FLEXEntitlement: getUserEntitlementStatus(
          userData.id,
          "flex",
          userData.edges?.entitlement,
        ),
        // NOWWinter2022Entitlement,
        // NOWSummer2022Entitlement,
        // etc.
      },
    )
    window.aptrinsic(
      "identify",
      // User Fields
      {
        id,
        email,
        hubspotId,
        ...userEntitlementAttributes,
      },
      // Account Fields
      {
        id: hubspotPrimaryCompanyId,
      },
    )
    logger.debug("Gainsight info: User data sent")
    return
  } catch (err) {
    logger.withScope({ tags: { caller: "sendDataToGainsight" } }).error(err)
    return
  }
}
