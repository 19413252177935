import { useEffect } from "react"
import { useRouter } from "~/hooks/use-router"
import * as gtm from "~/util/gtm"

/* istanbul ignore next */
const GoogleTagManager = ({ children }) => {
  const router = useRouter()

  useEffect(() => {
    router.events.on("routeChangeComplete", gtm.pageView)
    return () => {
      router.events.off("routeChangeComplete", gtm.pageView)
    }
  }, [router.events])

  return children
}

export default GoogleTagManager
