import * as Sentry from "@sentry/nextjs"

/* eslint-disable @typescript-eslint/no-explicit-any */
export const LEVELS = {
  DEBUG: "debug",
  INFO: "info",
  WARN: "warn",
  ERROR: "error",
} as const
type Level = typeof LEVELS[keyof typeof LEVELS]

const consoleLevelMap = {
  [LEVELS.DEBUG]: LEVELS.DEBUG,
  [LEVELS.INFO]: LEVELS.INFO,
  [LEVELS.WARN]: "warn",
  [LEVELS.ERROR]: LEVELS.ERROR,
} as const

export const sentrySeverityMap = {
  [LEVELS.DEBUG]: "debug",
  [LEVELS.INFO]: "info",
  [LEVELS.WARN]: "warning",
  [LEVELS.ERROR]: "error",
} as const

const isLevel = (() => {
  const levelValues = new Set(Object.values(LEVELS))
  return (level: Level) => levelValues.has(level)
})()

type Scope = {
  tags?: { [key: string]: Primitive }
  extras?: { [key: string]: Primitive }
  devConsole?: boolean
  sentry?: boolean
}
type LogParams = Scope & { level: Level; message: any[] }
interface Filter {
  (params: LogParams): LogParams | null
}

type LogFunc = (...m: any[]) => void
interface ScopedLogger {
  debug: LogFunc
  info: LogFunc
  warn: LogFunc
  error: LogFunc
}
interface Logger extends ScopedLogger {
  withScope: (scope: Scope) => Logger
  initArgs?: {
    sentry: typeof Sentry
    consoleLogger: typeof console
    environment: string
    filters: Filter[] | null
  }
}
const applyFilters =
  (filters: Filter[] | null) =>
  (params: LogParams): LogParams => {
    if (!filters) return params
    return filters.reduce((transformedParams, func) => {
      return Object.assign(transformedParams, func(transformedParams))
    }, params)
  }

export const init = (
  _sentry: typeof Sentry,
  consoleLogger: typeof console,
  environment = process.env.NODE_ENV,
  filters: Filter[] | null = null,
): Logger => {
  const filter = applyFilters(filters)
  const sentryWrapper = (
    level: Level,
    {
      tags,
      extras,
      devConsole = environment !== "production",
      sentry = environment === "production",
    }: Scope,
    ...message: any[]
  ) => {
    const {
      level: _level,
      tags: _tags,
      extras: _extras,
      devConsole: _devConsole,
      sentry: logWithSentry,
      message: _message,
    } = filter({ level, tags, extras, devConsole, sentry, message })
    if (!isLevel(_level)) return
    if (_devConsole) {
      const metadata = <Omit<Scope, "devConsole">>{}
      if (_tags) metadata.tags = _tags
      if (_extras) metadata.extras = _extras
      Object.keys(metadata).length
        ? consoleLogger[consoleLevelMap[_level]](..._message, { metadata })
        : consoleLogger[consoleLevelMap[_level]](..._message)
    }
    if (!logWithSentry) return
    _sentry.withScope(scope => {
      if (_tags) scope.setTags(_tags)
      if (_extras) scope.setExtras(_extras)
      scope.setLevel(sentrySeverityMap[_level])
      _level === LEVELS.ERROR
        ? _sentry.captureException(_message[0])
        : _sentry.captureMessage(_message.join(", "))
    })
  }

  const logger = <Logger>{
    withScope: ({ tags, extras, devConsole }: Scope) =>
      Object.values(LEVELS).reduce((accum, level) => {
        accum[level] = (...message) =>
          sentryWrapper(level, { tags, extras, devConsole }, ...message)
        return accum
      }, {} as ScopedLogger),
    ...Object.values(LEVELS).reduce((accum, level) => {
      accum[level] = (...message) => sentryWrapper(level, {}, ...message)
      return accum
    }, <ScopedLogger>{}),
  }
  logger.initArgs = {
    sentry: _sentry,
    consoleLogger,
    environment,
    filters,
  }
  return logger
}

export const logFilters = {
  withholdSentryDebug: function ({ level, ...attrs }: LogParams): LogParams {
    if (!level || level !== "debug") return { level, ...attrs }
    return {
      level,
      ...attrs,
      sentry: false,
    }
  },
}

/*
 * Allows the developer to define a named export "filters" in /src/dev/log-filters
 * which contains a set of functions to transform logging params with. Useful for hiding or
 * transforming error messages you maybe don't care about during development. */
const devFilters =
  process.env.NODE_ENV !== "production"
    ? ((): Filter[] => {
        try {
          // eslint-disable-next-line @typescript-eslint/no-var-requires,no-shadow
          const { filters } = require("@/dev/log-filters")
          return filters ?? []
        } catch (e) {
          return []
        }
      })()
    : []
/*
 * Exposes a consistent logging API for development and production environments.
 * Examples:
 *   logger.info("hello") // logs to Sentry, and, if not in development, also to the dev console
 *   logger.withScope({ devConsole: true }).info("sup") // logs to dev console in prod and development
 *   logger.withScope({ devConsole: false }).info("sup") // never logs to the dev console
 *   logger.withScope({ sentry: false, devConsole: true }).info("sup") // log to dev console but not sentry
 *   logger.withScope({ tags: { foo: 1, bar: 2 }, extras: { foo: 1, bar: 2 }}).info("foobar")
 *   */
export default init(Sentry, console, process.env.NODE_ENV, [
  // never send debug logs to sentry while in production
  logFilters.withholdSentryDebug,
  ...devFilters,
])
