import createCache from "@emotion/cache"
import createEmotionServer from "@emotion/server/create-instance"
import { ServerStyleSheets } from "@mui/styles"
import clsx from "clsx"
import Document, { Head, Html, Main, NextScript } from "next/document"
import { Fragment } from "react"
import React from "react"
import { theme } from "@/styles/admin-theme"
import { getCssText, globalStitchesStyles } from "@/styles/stitches.config"
import { loadGainsightScript } from "@/vendor/gainsight"
import { GTM_ID } from "~/util/gtm"

/* Google Tag Manager - Global base code */
function getGTMScript() {
  if (GTM_ID) {
    return (
      <script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer', '${GTM_ID}');
          `,
        }}
      />
    )
  } else {
    return null
  }
}

function getHubSpotScript() {
  const accountID = process.env.NEXT_PUBLIC_HUBSPOT_ACCOUNT_ID ?? "19907838"
  return (
    <script
      type="text/javascript"
      id="hs-script-loader"
      async
      defer
      src={`//js.hs-scripts.com/${accountID}.js`}
    />
  )
}

function getPreConnectLinks() {
  const preConnectUrls = [
    process.env.NEXT_PUBLIC_AOEU_API_URL,
    process.env.NEXT_PUBLIC_FEATURE_SERVICE_URL,
  ]
  return (
    <>
      {preConnectUrls.map(url => {
        if (url) {
          return (
            <Fragment key={`preconnect-url-${url}`}>
              <link rel="preconnect" href={url} />
              <link rel="dns-prefetch" href={url} />
            </Fragment>
          )
        }
      })}
    </>
  )
}

/* Google Tag Manager - Global base code */
function getGTMNoScript() {
  if (GTM_ID) {
    return (
      <noscript>
        <iframe
          title="Tag manager no script frame"
          src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`}
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        />
      </noscript>
    )
  } else {
    return ""
  }
}

function getStitchesCSS() {
  return (
    <style id="stitches" dangerouslySetInnerHTML={{ __html: getCssText() }} />
  )
}

export default class MainDocument extends Document {
  render() {
    globalStitchesStyles()
    return (
      <Html>
        <Head>
          <meta name="theme-color" content={theme.palette.primary.main} />
          <link rel="icon" href="/aoeu-logo-mark.svg" type="image/svg" />
          <link rel="stylesheet" href="https://use.typekit.net/fmc7wgn.css" />
          {getPreConnectLinks()}
          {getGTMScript()}
          {getHubSpotScript()}
          {loadGainsightScript()}
          {getStitchesCSS()}
          <meta name="emotion-insertion-point" content="" />
          {this.props.emotionStyleTags}
        </Head>
        <body
          className={clsx(
            !this.props.__NEXT_DATA__.page.startsWith("/admin") && "main-app",
          )}>
          {getGTMNoScript()}
          <Main />
          <NextScript />
        </body>
      </Html>
    )
  }
}

// See https://github.com/mui/material-ui/blob/69a8436e178d3b475e08314ed765520fe7b03773/examples/nextjs/pages/_document.js
// for explanation of Emotion styling
MainDocument.getInitialProps = async ctx => {
  const sheets = new ServerStyleSheets()
  const originalRenderPage = ctx.renderPage

  // You can consider sharing the same Emotion cache between all the SSR requests to speed up performance.
  // However, be aware that it can have global side effects.
  const cache = createEmotionCache()
  const { extractCriticalToChunks } = createEmotionServer(cache)

  ctx.renderPage = () =>
    originalRenderPage({
      enhanceApp: App => props =>
        sheets.collect(<App emotionCache={cache} {...props} />),
    })

  const initialProps = await Document.getInitialProps(ctx)
  // This is important. It prevents Emotion to render invalid HTML.
  // See https://github.com/mui/material-ui/issues/26561#issuecomment-855286153
  const emotionStyles = extractCriticalToChunks(initialProps.html)
  const emotionStyleTags = emotionStyles.styles.map(style => (
    <style
      data-emotion={`${style.key} ${style.ids.join(" ")}`}
      key={style.key}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: style.css }}
    />
  ))

  return {
    ...initialProps,
    styles: [
      ...React.Children.toArray(initialProps.styles),
      sheets.getStyleElement(),
    ],
    emotionStyleTags,
  }
}

const isBrowser = typeof document !== "undefined"

// On the client side, Create a meta tag at the top of the <head> and set it as insertionPoint.
// This assures that MUI styles are loaded first.
// It allows developers to easily override MUI styles with other styling solutions, like CSS modules.
export function createEmotionCache() {
  let insertionPoint

  if (isBrowser) {
    const emotionInsertionPoint = document.querySelector(
      'meta[name="emotion-insertion-point"]',
    )
    insertionPoint = emotionInsertionPoint ?? undefined
  }

  return createCache({ key: "mui-style", insertionPoint })
}
