/* eslint-disable @typescript-eslint/ban-ts-comment */
import { setCookie } from "cookies-next"
import { clone, merge } from "lodash"
import {
  createContext,
  Dispatch,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from "react"
import { breakpointPx } from "@/styles/stitches.config"
import { useViewport } from "~/hooks/use-viewport"

export type UserPreferences = {
  sidebarOpen: boolean
}

const defaultPreferences: UserPreferences = {
  sidebarOpen: true,
}

export const UserPreferencesContext = createContext<
  UserPreferences | undefined
>(undefined)

/**
 * Custom hook for accessing user preferences stored in `preferences` cookie.
 * @todo Relocate this state and logic to the provider to avoid potential race conditions.
 */
export const usePreferences = (): [
  UserPreferences,
  Dispatch<Partial<UserPreferences>>,
] => {
  const { width } = useViewport()
  const initialPreferences = useContext(UserPreferencesContext)

  if (initialPreferences === undefined) {
    throw new Error("usePreferences must be used within a PreferencesProvider")
  }

  const [preferences, setPreferences] = useReducer(
    (currentState: UserPreferences, newState: Partial<UserPreferences>) =>
      Object.assign({}, merge(currentState, newState)),
    initialPreferences,
  )
  const sidebarOpenRef = useRef(preferences.sidebarOpen)

  useEffect(() => {
    // if the user is in mobile, we never keep the sidebar open after a navigation
    if (width < breakpointPx.m && sidebarOpenRef.current) {
      setPreferences({ sidebarOpen: false })
    }

    sidebarOpenRef.current = preferences.sidebarOpen
  }, [width])

  useEffect(() => {
    // changes to preferences are write-only on the client, read-only on the server
    setCookie("preferences", JSON.stringify(preferences))
  }, [preferences])

  return [preferences, setPreferences]
}

export function PreferencesProvider({
  children,
  serializedPreferences = "{}",
}: {
  children: ReactNode
  serializedPreferences: string
}): ReactElement {
  let preferences = clone(defaultPreferences)

  try {
    preferences = merge(preferences, JSON.parse(serializedPreferences))
  } catch (e) {
    // The full stack trace here results in a lot of noise, so we abbreviate it.
    // Errors here happen when there isn't yet a preferences cookie and typically
    // isn't a sign of a problems.
    console.warn("Failed to load user preferences: ", String(e))
  }

  return (
    <UserPreferencesContext.Provider value={preferences}>
      {children}
    </UserPreferencesContext.Provider>
  )
}
