import { GetServerSideProps } from "next"
import React, { createContext } from "react"
import { pages } from "@/urls"
import { TrackingRouter } from "~/hooks/use-router"
import { strip, isEmpty } from "~/util"
import { navigateTo } from "~/util/routing"

export const getRedirectUrl = ({
  returnTo = null,
  referer = null,
}: {
  returnTo?: string | null
  referer?: string | null
}): string | null => {
  let redirectTo = returnTo ?? referer ?? null
  // If the referer is an Auth0 URL, then we don't want to use it as a redirect
  // path within our application since it would be an invalid URL and would
  // display a 404 error.
  if (
    !redirectTo ||
    redirectTo.startsWith(`${process.env.NEXT_PUBLIC_AUTH0_ISSUER}`)
  )
    return null
  try {
    const asUrl = new URL(redirectTo)
    redirectTo = `${asUrl.pathname}${asUrl.search}`
  } catch (e) {
    // if it's not a valid URL, then it didn't start with http(s) and it's safe
    // to redirect them to the indicated location
  }
  return `/${strip(redirectTo, "/")}`.replace(/\/{2,}/g, "/")
}

type Obj = Record<string, string>
type NestedObj = Record<string, Obj | string>
// eslint-disable-next-line no-unused-vars
const { internalUrls, blacklistedUrls, badReturnToUrls } = (target => {
  const blacklist = ["/maintenance", "/user/", "/account/"]

  // eslint-disable-next-line no-shadow
  const internalUrls = [] as string[]
  // eslint-disable-next-line no-shadow
  const blacklistedUrls = [] as string[]
  // eslint-disable-next-line no-shadow
  const badReturnToUrls = [
    pages.user.login,
    pages.user.logout,
    pages.user.social,
  ]

  const getValues = (node: Obj | NestedObj) => {
    Object.keys(node).forEach(key => {
      if (typeof node[key] === "object") {
        getValues(node[key] as Obj)
      } else {
        const value = String(node[key])
        internalUrls.push(value)

        if (blacklist.some(b => value === b || value.includes(b))) {
          blacklistedUrls.push(value)
        }
      }
    })
  }

  getValues(target as unknown as NestedObj)

  return { internalUrls, blacklistedUrls, badReturnToUrls }
})(pages)

export type RedirectCtx = Partial<{
  redirectTo: string | null
  route: string
  redirectAfterLogin: () => void
}>
export const RedirectContext = createContext({} as RedirectCtx)

export function RedirectProvider({
  router,
  referer: _referer,
  children,
}: {
  router: TrackingRouter
  referer?: string
  children: React.ReactNode
}): React.ReactNode {
  const {
    route,
    query: { returnTo },
  } = router

  const [referer, unsetReferer] = React.useReducer(() => "", _referer ?? "")
  let redirectTo = getRedirectUrl({
    returnTo: returnTo ? String(returnTo) : null,
    referer,
  })
  if (badReturnToUrls.some(badUrl => (redirectTo || "").startsWith(badUrl))) {
    redirectTo = null
  }
  const redirectAfterLogin = React.useMemo(() => {
    /* If the target URL to redirect the user to after successful login includes
    any of the blacklisted urls, then the user should be redirected to the
    home page. */
    return (destination = redirectTo) => {
      unsetReferer()
      navigateTo(
        isEmpty(destination) || blacklistedUrls.includes(destination ?? "")
          ? pages.home
          : destination,
      )
    }
  }, [redirectTo])

  return (
    <RedirectContext.Provider value={{ redirectTo, route, redirectAfterLogin }}>
      {children}
    </RedirectContext.Provider>
  )
}

type TWithSearchQueryParamCheckOptions = {
  redirectPathIfNoQuery: string
}

// A higher-order function to wrap getServerSideProps functions with a query check.
export function withSearchQueryParamCheck(
  gssp: GetServerSideProps,
  { redirectPathIfNoQuery }: TWithSearchQueryParamCheckOptions,
): GetServerSideProps {
  return async context => {
    const { query, resolvedUrl = "" } = context
    const isPro = resolvedUrl.includes("/pro")

    if (isPro && !query?.query) {
      return {
        redirect: {
          destination: redirectPathIfNoQuery,
          permanent: false,
        },
      }
    }

    return gssp(context)
  }
}
