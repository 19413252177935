import DeepClone from "rfdc" // Really Fast Deep Clone

export function clone(target) {
  const dc = new DeepClone({
    proto: false,
    circles: false,
  })

  return dc(target)
}
