import HttpStatus from "http-status"
import { ApiClient } from "~/services/aoeu"
import { ProductPricePayload } from "~/services/aoeu/models"

export type ParsedPrice = {
  productId: string
  productName: string
  productDisplayLabel: string
  productDescription: Optional<string, null>
  productPriceId: string
  priceLookupKey: string
  priceItemCode: string
  name: string
  amount: number
}
export function parseProductPrices(
  data: ProductPricePayload[],
): Record<string, ParsedPrice> {
  return data.reduce((result, item) => {
    if (item && item.product && item?.lookup_key) {
      result[item.lookup_key] = {
        productId: item.product.id,
        productName: item.product.name,
        productDisplayLabel: item.product.description,
        productDescription: item.product.metadata?.product_description ?? null,
        productPriceId: item.id,
        priceLookupKey: item.lookup_key,
        /* If there's no ItemCode set on the price metadata, fallback to the lookup key;
           in historical data, lookup_key === metadata.ItemCode. Ultimately, itemCode
           is used solely for tax calculations. */
        priceItemCode: item.metadata?.ItemCode ?? item.lookup_key,
        name: item.nickname,
        amount: (item.unit_amount ?? 0) / 100,
      }
    }

    return result
  }, {} as Record<string, ParsedPrice>)
}

export async function loadProductPrices({
  api,
}: {
  api: ApiClient
}): Promise<Record<string, ParsedPrice>> {
  const { status, data } = await api.getProductPrices()

  let productPrices = {}

  if (status === HttpStatus.OK) {
    productPrices = parseProductPrices(data)
  }

  return productPrices
}
