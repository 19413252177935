/**
 * Returns an array of the keys of the given object.
 * @param {T} obj - The object whose keys are to be returned.
 * @returns {(keyof T)[]} - An array of the keys of the given object.
 */
export function safeKeys<T extends object>(obj: T): (keyof T)[] {
  return Object.keys(obj) as (keyof T)[]
}

/**
 * Returns a new object that includes the properties of the given object that do not satisfy the given predicate. <br>
 * This should be considered a drop-in replacement for `_.omitBy`.
 * The motivation for reimplementing this function is to avoid
 * [dynamic code evaluation in Next middleware](https://nextjs.org/docs/messages/edge-dynamic-code-evaluation)
 * @param {T} obj - The object to filter.
 * @param {(value: unknown, key: keyof T) => boolean} predicate - The function to determine which properties to omit.
 * @returns {Partial<T>} - A new object that includes the properties of the given object that do not satisfy the given predicate.
 */
export function omitBy<T extends object>(
  obj: T,
  predicate: (value: unknown, key: keyof T) => boolean,
): Partial<T> {
  return safeKeys(obj).reduce((acc, key) => {
    if (!predicate(obj[key], key)) {
      acc[key] = obj[key]
    }
    return acc
  }, {} as Partial<T>)
}

/**
 * Returns a new object that includes the properties of the given object that satisfy the given predicate. <br>
 * This should be considered a drop-in replacement for `_.pickBy`.
 * The motivation for reimplementing this function is to avoid
 * [dynamic code evaluation in Next middleware](https://nextjs.org/docs/messages/edge-dynamic-code-evaluation)
 * @param {T} obj - The object to filter.
 * @param {(value: unknown, key: keyof T) => boolean} predicate - The function to determine which properties to pick.
 * @returns {Partial<T>} - A new object that includes the properties of the given object that satisfy the given predicate.
 */
export function pickBy<T extends object>(
  obj: T,
  predicate: (value: unknown, key: keyof T) => boolean,
): Partial<T> {
  return safeKeys(obj).reduce((acc, key) => {
    if (predicate(obj[key], key)) {
      acc[key] = obj[key]
    }
    return acc
  }, {} as Partial<T>)
}

/**
 * Returns a new object with the same keys as the given object, but with each value transformed by the given mapper function. <br>
 * This should be considered a drop-in replacement for `_.mapValues`.
 * The motivation for reimplementing this function is to avoid
 * [dynamic code evaluation in Next middleware](https://nextjs.org/docs/messages/edge-dynamic-code-evaluation)
 * @param {T} obj - The object to map.
 * @param {(value: T[keyof T], key: keyof T) => U} mapper - The function to transform the values.
 * @returns {Record<keyof T, U>} - A new object with the same keys as the given object, but with each value transformed by the given mapper function.
 */
export function mapValues<T extends object, U>(
  obj: T,
  mapper: (value: T[keyof T], key: keyof T) => U,
): Record<keyof T, U> {
  return safeKeys(obj).reduce((acc, key) => {
    acc[key] = mapper(obj[key], key)
    return acc
  }, {} as Record<keyof T, U>)
}
