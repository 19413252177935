/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/ban-ts-comment,camelcase */
import { Static, TSchema, Type } from "@sinclair/typebox"
import { Value } from "@sinclair/typebox/value"
import { format } from "date-fns"
import HttpStatus from "http-status"
import { GetServerSidePropsContext, NextPageContext } from "next"
import { api, ContentAPI, TrackingAPI } from "@/urls"
import { CURRENCY } from "~/enums"
import { asHTTPError, HttpRequestError } from "~/errors/http-request-error"
import { NoContentResponseType } from "~/services/aoeu/mock-portal-data"
import {
  EmailPayload,
  UserTraitsPayload,
  ProfileDataPayload,
  UserDataPayload,
  FlagsPayload,
  Trait,
  SetUserTraitsPayload,
  RolePayload,
  SSNPayload,
  OrganizationStatus,
  OrganizationPayload,
  OrgUserPayload,
  EntitlementUpdatePayload,
  ProductPayload,
  BulkUploadPayload,
  FLEXIndividualLessonPayload,
  FLEXAssetPayloadBox,
  FLEXIndividualLessonPayloadBox,
  FLEXSearchResult,
  FLEXAssetPayload,
  FLEXCollectionSearchPayload,
  FLEXCollectionSearchPayloadBox,
  FLEXIndividualCollectionPayload,
  FLEXIndividualCollectionPayloadBox,
  PROPackPayloadBox,
  PROPackPayload,
  ImplementationSearchResult,
  ImplementationSearchResultBox,
  ImplementationDetailPayload,
  ImplementationDetailPayloadBox,
  TrackingPayloadType,
  NOWConfPayloadType,
  LoginDataPayload,
  OIDCLoginPayload,
  NOWConfPayloadBox,
  ProductPricePayload,
  ProductPricePayloadBox,
  FLEXCurriculumType,
  FLEXCurriculumBox,
  FLEXBookmarkCreateResponse,
  FLEXBookmarkByAssetResponse,
  FLEXBookmarksResponse,
  OrganizationProductMetrics,
} from "~/services/aoeu/models"
import {
  FLEXBaseClassPayload,
  FLEXBaseUnitPayload,
  FLEXClassPayload,
  FLEXMyClassesPayload,
  FLEXMyClassesPayloadBox,
  FLEXUnitPayload,
  FLEXBaseClassPayloadBox,
  FLEXBaseUnitPayloadBox,
  FLEXClassPayloadBox,
  FLEXUnitPayloadBox,
} from "~/services/aoeu/models.my-classes"
import { CastSanitySearch } from "~/services/aoeu/models.sanity"
import {
  HttpClient,
  RequestOptions,
  RequestResponse,
} from "~/services/http-client"
import {
  isNotEmpty,
  isEmpty,
  rstrip,
  lstrip,
  normalizeWhitespace,
} from "~/util"
import logger from "~/util/logger"
import { mapValues, omitBy, pickBy } from "~/util/object.utils"

type BaseAPIOptions = Partial<{
  accessToken: string
  csrfToken: string | null
  headers: any
  baseUrl: string
  trailingSlash: boolean
}>

class BaseApiClient {
  http: HttpClient

  constructor({
    accessToken = "",
    csrfToken = null,
    headers = {},
    baseUrl = api.baseUrl,
    trailingSlash = true,
  }: BaseAPIOptions = {}) {
    this.http = new HttpClient(
      baseUrl,
      accessToken,
      csrfToken,
      headers,
      trailingSlash,
    )
  }

  protected async upsert({
    url,
    data,
    options,
  }: {
    url: string
    data: Record<string, JSONValue>
    options?: RequestOptions
  }): Promise<RequestResponse> {
    try {
      return await this.http.patch(url, data, options)
    } catch (e) {
      const error = asHTTPError(e)
      const doesNotExist =
        error.status === HttpStatus.NOT_FOUND ||
        error.data?.error.indexOf("not found") !== -1
      if (doesNotExist) return await this.http.post(url, data, options)
      throw e
    }
  }
}

export class ApiClient extends BaseApiClient {
  async createUser(userData: {
    email: string
    firstName: string
    lastName: string
    password: string
  }): Promise<RequestResponse> {
    return this.http.post(api.user(), userData)
  }

  async verifyUserEmail({
    email,
    emailId,
    token,
  }: {
    email: string
    emailId: string
    token: string
  }): Promise<RequestResponse> {
    return this.http.post(
      api.authVerifyEmail,
      {
        email,
        emailId,
        token,
      },
      { attachCookie: true },
    )
  }

  async login({
    email,
    password,
    rememberMe,
  }: {
    email: string
    password: string
    rememberMe: boolean
  }): Promise<RequestResponse<LoginDataPayload>> {
    return this.http.post(api.authLogin, {
      email,
      password,
      rememberMe,
    })
  }

  async refresh(): Promise<RequestResponse> {
    return this.http.post(api.authRefresh, {})
  }

  async logout(): Promise<RequestResponse> {
    return this.http.post(api.authLogout, {})
  }

  async getUserById(id: string): Promise<RequestResponse> {
    return this.http.get(api.user(id))
  }

  async createUserProfile(
    userId: string,
    profileData: ProfileDataPayload,
  ): Promise<RequestResponse<ProfileDataPayload>> {
    return this.http.post(api.userProfile(userId), profileData)
  }

  async getUserProfile(
    id: string,
  ): Promise<RequestResponse<ProfileDataPayload>> {
    return this.http.get(api.userProfile(id))
  }

  async updateUserProfile(
    id: string,
    updatedUserProfile: Partial<ProfileDataPayload>,
  ): Promise<RequestResponse> {
    return this.http.patch(api.userProfile(id), updatedUserProfile)
  }

  async upsertProfile(
    id: string,
    data: Partial<ProfileDataPayload>,
  ): Promise<RequestResponse<ProfileDataPayload>> {
    const url = api.userProfile(id)
    // The API does not like empty strings, so we provide null values instead.
    return this.upsert({
      url,
      data: mapValues(data, v => (v === "" ? null : v)),
    })
  }

  async forgotPassword({ email }: { email: string }): Promise<RequestResponse> {
    return this.http.post(api.forgotPassword, { email })
  }

  async loginWithOIDC({
    provider,
    idToken,
    firstName,
    lastName,
  }: {
    provider: string
    idToken: string
    firstName?: string
    lastName?: string
  }): Promise<RequestResponse<OIDCLoginPayload>> {
    return this.http.post(
      api.providerLogin,
      {
        provider,
        idToken,
        firstName,
        lastName,
      },
      { attachCookie: true },
    )
  }

  async userAddEmailAddress({
    userId,
    emailAddress,
  }: {
    userId: string
    emailAddress: string
  }): Promise<RequestResponse<EmailPayload>> {
    return this.http.post(api.addEmail(userId), { email: emailAddress })
  }

  async resendVerifyEmail({
    email,
  }: {
    email: string
  }): Promise<RequestResponse> {
    return this.http.post(api.resendVerifyEmail, { email })
  }

  async userSetPrimaryEmail({
    userId,
    emailId,
  }: {
    userId: string
    emailId: number
  }): Promise<RequestResponse> {
    return this.http.post(api.setPrimaryEmail(userId), { emailId })
  }

  async userDeleteEmailAddress({
    userId,
    emailId,
  }: {
    userId: string
    emailId: number
  }): Promise<RequestResponse> {
    return this.http.delete(api.deleteEmail({ userId, emailId }))
  }

  async getStudentInfo({
    userId,
  }: {
    userId: string
  }): Promise<RequestResponse> {
    return this.http.get(api.studentInfo({ userId }))
  }

  async setStudentInfo({
    userId,
    SSN,
  }: {
    userId: string
    SSN: string
  }): Promise<RequestResponse> {
    return this.http.post(api.studentInfo({ userId }), { SSN })
  }

  async getProductPrices(): Promise<RequestResponse<ProductPricePayload[]>> {
    return cast(
      this.http.get(api.productPrices),
      Type.Array(ProductPricePayloadBox),
    )
  }

  async getUserSubscriptions(userId: string): Promise<RequestResponse> {
    return this.http.get(api.userSubscriptions({ userId }))
  }

  async getUserTickets(userId: string): Promise<RequestResponse> {
    return this.http.get(api.userTickets({ userId }))
  }

  async getEstimatedTax({
    userId,
    productPrice,
    billingInfo,
    discountCode,
  }: {
    userId: string
    productPrice: {
      amount: string
      priceItemCode: string
    }
    billingInfo: {
      billingAddress: string
      city: string
      state: string
      country: string
      postalCode: string
    }
    discountCode?: string
  }): Promise<RequestResponse> {
    type Line = {
      quantity: number
      amount: number
      itemCode: string
      discountCode?: string
    }
    const line: Line = {
      quantity: 1,
      amount: parseFloat(productPrice.amount),
      itemCode: productPrice.priceItemCode,
      ...(isNotEmpty(discountCode) ? { discountCode } : {}),
    }

    const lines = [line]

    return this.http.post(api.taxEstimate, {
      lines,
      currencyCode: CURRENCY.USD,
      customerCode: userId,
      addresses: {
        ShipTo: {
          line1: billingInfo.billingAddress,
          city: billingInfo.city,
          region: billingInfo.state,
          country: billingInfo.country,
          postalCode: billingInfo.postalCode,
        },
      },
    })
  }

  async startSubscriptionPurchase({
    userId,
    billingInfo,
  }: {
    userId: string
    billingInfo: {
      name: string
      line1: string
      line2?: string
      city: string
      state: string
      postalCode: string
      country: string
    }
  }): Promise<RequestResponse> {
    return this.http.post(api.startSubscriptionPurchase, {
      userId,
      billingInformation: billingInfo,
    })
  }

  async completeSubscriptionPurchase({
    userId,
    productId,
    priceId,
    paymentMethodId,
    discountCode,
  }: {
    userId: string
    productId: string
    priceId: string
    paymentMethodId: string
    discountCode?: string
  }): Promise<RequestResponse> {
    return this.http.post(api.completeSubscriptionPurchase, {
      userId,
      productId,
      priceId,
      paymentMethodId,
      discountCode,
    })
  }

  async validateSubscriptionCoupon({
    userId,
    productId,
    priceId,
    discountCode,
  }: {
    userId: string
    productId: string
    priceId: string
    discountCode: string
  }): Promise<RequestResponse> {
    return this.http.post(api.validateSubscriptionCoupon, {
      userId,
      productId,
      priceId,
      discountCode,
    })
  }

  async startProductPurchase({
    userId,
    priceLookupKey,
    billingInfo,
    discountCode,
  }: {
    userId: string
    priceLookupKey: string
    billingInfo: {
      name: string
      line1: string
      line2?: string
      city: string
      state: string
      postalCode: string
      country: string
    }
    discountCode?: string
  }): Promise<RequestResponse> {
    return this.http.post(api.startProductPurchase, {
      userId,
      priceLookupKey,
      billingInformation: billingInfo,
      discountCode,
    })
  }

  async validateProductCoupon({
    userId,
    priceLookupKey,
    discountCode,
  }: {
    userId: string
    priceLookupKey: string
    discountCode: string
  }): Promise<RequestResponse> {
    return this.http.post(api.validateProductCoupon, {
      userId,
      priceLookupKey,
      discountCode,
    })
  }

  async completeProductPurchase({
    paymentId,
  }: {
    paymentId: string
  }): Promise<RequestResponse> {
    return this.http.post(api.completeProductPurchase, { paymentId })
  }

  async getCustomerPortal({
    userId,
    returnURL,
  }: {
    userId: string
    returnURL: string
  }): Promise<RequestResponse> {
    return this.http.post(api.customerPortal, {
      userId,
      returnURL,
    })
  }

  async getFlags(): Promise<RequestResponse<FlagsPayload>> {
    return this.http.get(api.features.flags, {
      headers: {
        "X-Environment-Key":
          process.env.NEXT_PUBLIC_FEATURE_SERVICE_API_KEY ?? "",
      },
    })
  }

  async getUserTraits({
    featureServiceId,
  }: {
    featureServiceId: string
  }): Promise<RequestResponse<UserTraitsPayload>> {
    return this.http.get(
      { path: api.features.userFlags, query: `identifier=${featureServiceId}` },
      {
        headers: {
          "X-Environment-Key":
            process.env.NEXT_PUBLIC_FEATURE_SERVICE_API_KEY ?? "",
        },
      },
    )
  }

  async setUserTraits({
    featureServiceId,
    traits,
  }: {
    featureServiceId: string
    traits: Trait[]
  }): Promise<RequestResponse<SetUserTraitsPayload>> {
    const body = traits.map(t => ({
      identity: { identifier: featureServiceId },
      trait_key: t.trait_key,
      trait_value: t.trait_value,
    }))

    return this.http.put(api.features.traits, body, {
      headers: {
        "X-Environment-Key":
          process.env.NEXT_PUBLIC_FEATURE_SERVICE_API_KEY ?? "",
      },
    })
  }

  async listClasses(): Promise<RequestResponse<FLEXMyClassesPayload>> {
    return cast(this.http.get(api.class.list), FLEXMyClassesPayloadBox)
  }

  async createClass({
    title,
    description,
  }: Pick<FLEXBaseClassPayload, "title" | "description">): Promise<
    RequestResponse<FLEXBaseClassPayload>
  > {
    return cast(
      this.http.post(
        api.class.create,
        pickBy(
          {
            title,
            description,
          },
          isNotEmpty,
        ),
      ),
      FLEXBaseClassPayloadBox,
    )
  }

  async getClass(classId: number): Promise<RequestResponse<FLEXClassPayload>> {
    return cast(this.http.get(api.class.detail(classId)), FLEXClassPayloadBox)
  }

  async updateClass(
    classId: number,
    data: Partial<FLEXBaseClassPayload>,
  ): Promise<RequestResponse<FLEXBaseClassPayload>> {
    // The API does not like empty strings, so we provide null values instead.
    const sanitizedData = mapValues(data, v => (v === "" ? null : v))
    return cast(
      this.http.patch(api.class.detail(classId), sanitizedData),
      FLEXClassPayloadBox,
    )
  }

  async deleteClass(classId: number): Promise<NoContentResponseType> {
    return this.http.delete(api.class.detail(classId))
  }

  async reorderClasses(
    userId: string,
    order: number[],
    organizationId?: number,
  ): Promise<NoContentResponseType> {
    return this.http.post(api.class.ops.reorder, {
      userId,
      order,
      organizationId,
    })
  }

  async reorderClassUnits(
    classId: number,
    order: number[],
  ): Promise<NoContentResponseType> {
    return this.http.patch(api.class.detail(classId), {
      unitOrder: order,
    })
  }

  async cloneClass(
    classId: number,
    title?: string,
  ): Promise<RequestResponse<FLEXClassPayload>> {
    return cast(
      this.http.post(api.class.ops.clone(classId), omitBy({ title }, isEmpty)),
      FLEXClassPayloadBox,
    )
  }

  async cloneCurriculum(
    curriculumId: string,
  ): Promise<RequestResponse<FLEXClassPayload>> {
    return cast(
      this.http.post(api.class.ops.cloneCurriculum(curriculumId)),
      FLEXClassPayloadBox,
    )
  }

  async assignClassOwner(
    classId: number,
    data: { userId?: string; organizationId?: number; title?: string },
  ): Promise<RequestResponse<FLEXBaseClassPayload>> {
    return cast(
      this.http.post(api.class.ops.assignOwner(classId), omitBy(data, isEmpty)),
      FLEXBaseClassPayloadBox,
    )
  }

  async assignClassUnit(
    classId: number,
    unitId: number,
  ): Promise<RequestResponse<FLEXClassPayload>> {
    return cast(
      this.http.post(api.class.ops.assignUnits(classId), [
        {
          id: unitId,
        },
      ]),
      FLEXClassPayloadBox,
    )
  }

  async removeClassUnit(
    classId: number,
    unitId: number,
  ): Promise<RequestResponse<FLEXBaseClassPayload>> {
    return cast(
      this.http.post(api.class.ops.removeUnits(classId), [
        {
          id: unitId,
        },
      ]),
      FLEXBaseClassPayloadBox,
    )
  }

  async appendClassAsset(
    classId: number,
    assetId: string,
  ): Promise<NoContentResponseType> {
    return this.http.post(api.class.ops.appendAsset(classId), { id: assetId })
  }

  async bulkAssetOp(
    ops: ({ assetId: string; action: "add" | "remove" } & (
      | {
          classId?: number
          unitId?: never
        }
      | {
          classId?: never
          unitId?: number
        }
    ))[],
  ): Promise<NoContentResponseType> {
    return this.http.post(api.class.ops.bulkAssetOp, ops)
  }

  async createUnit({
    title,
    description,
    classId,
  }: Pick<FLEXBaseUnitPayload, "title" | "description"> & {
    classId?: number
  }): Promise<RequestResponse<FLEXBaseUnitPayload>> {
    return cast(
      this.http.post(
        api.unit.create,
        pickBy(
          {
            title,
            description,
            classId,
          },
          isNotEmpty,
        ),
      ),
      FLEXBaseUnitPayloadBox,
    )
  }

  async getUnit(unitId: number): Promise<RequestResponse<FLEXUnitPayload>> {
    return cast(this.http.get(api.unit.detail(unitId)), FLEXUnitPayloadBox)
  }

  async updateUnit(
    unitId: number,
    data: Partial<FLEXBaseUnitPayload> | { assets: { id: string }[] },
  ): Promise<RequestResponse<FLEXBaseUnitPayload>> {
    return cast(
      // @ts-ignore NumericDictionary is not assignable to type JSONValue ??
      this.http.patch(api.unit.detail(unitId), pickBy(data, isNotEmpty)),
      FLEXBaseUnitPayloadBox,
    )
  }

  async deleteUnit(unitId: number): Promise<NoContentResponseType> {
    return this.http.delete(api.unit.detail(unitId))
  }

  async cloneUnit(
    unitId: number,
    { title, classId }: { title?: string; classId?: number },
  ): Promise<RequestResponse<FLEXBaseUnitPayload>> {
    return cast(
      this.http.post(
        api.unit.ops.clone(unitId),
        omitBy({ title, classId }, isEmpty),
      ),
      FLEXBaseUnitPayloadBox,
    )
  }

  async appendUnitAsset(
    unitId: number,
    assetId: string,
  ): Promise<NoContentResponseType> {
    return this.http.post(api.unit.ops.appendAsset(unitId), { id: assetId })
  }

  async getProPackRedirect(
    userId: string,
    courseId: number,
  ): Promise<RequestResponse<{ Link: string }>> {
    return this.http.post(api.bsSso.proCourse(userId), {
      courseId,
    })
  }

  async getProInProgressRedirect(
    userId: string,
  ): Promise<RequestResponse<{ Link: string }>> {
    return this.http.post(api.bsSso.proCourse(userId), {
      courseId: process.env.NEXT_PUBLIC_BRIGHTSPACE_PD_LANDING_ORG_ID,
    })
  }

  async getProCertificatesRedirect(
    userId: string,
  ): Promise<RequestResponse<{ Link: string }>> {
    return this.http.post(api.bsSso.proCourse(userId), {
      courseId: process.env.NEXT_PUBLIC_BRIGHTSPACE_PD_DEPARTMENT_ORG_ID,
    })
  }

  async getProHigherEdRedirect(
    userId: string,
  ): Promise<RequestResponse<{ Link: string }>> {
    return this.http.get(api.bsSso.higherEd(userId))
  }

  async getNowCert(productName: string): Promise<RequestResponse<Blob>> {
    // the HTTP client is not setup to handle Blobs, so we do some special handling here.
    const res = await fetch(
      new URL(api.nowCertificate(productName), this.http.baseUrl),
      {
        credentials: "include",
      },
    )
    if (res.status !== 200)
      throw HttpRequestError.fromResponse(
        res,
        "Failure while fetching NOW certificate payload",
      )
    return {
      status: res.status,
      data: await res.blob(),
    }
  }

  async getNowAfterPasses(): Promise<RequestResponse<NOWConfPayloadType[]>> {
    return cast(
      this.http.get(api.userNOWConferences),
      Type.Array(NOWConfPayloadBox),
    )
  }

  async getAllNowConferences(): Promise<RequestResponse<NOWConfPayloadType[]>> {
    return this.http.get(api.allNOWConferences)
  }

  async resendDistrictAdminWelcomeEmail(
    orgId: number,
  ): Promise<RequestResponse<NoContentResponseType>> {
    return this.http.post(api.resendDistrictAdminWelcomeEmail(orgId))
  }

  async createBookmark(
    assetId: string,
  ): Promise<RequestResponse<FLEXBookmarkCreateResponse>> {
    return this.http.post(api.bookmark.create, {
      asset_id: assetId,
    })
  }

  async getBookmarkByAssetId(
    assetId: string,
  ): Promise<RequestResponse<FLEXBookmarkByAssetResponse>> {
    return this.http.get(api.bookmark.getByAssetId(assetId))
  }

  async deleteBookmark(bookmarkId: string): Promise<NoContentResponseType> {
    return this.http.delete(api.bookmark.delete(bookmarkId))
  }

  async getBookmarks(options?: {
    page?: number
    sortBy?: "created" | "alpha"
    type?: string
  }): Promise<RequestResponse<FLEXBookmarksResponse>> {
    const queryParams = new URLSearchParams()

    if (options?.page) {
      queryParams.append("page", String(options.page))
    }

    if (options?.sortBy) {
      queryParams.append("sort_by", options.sortBy)
    }

    if (options?.type) {
      queryParams.append("type", options.type)
    }

    return this.http.get({
      path: api.bookmark.list,
      query: queryParams.toString(),
    })
  }
}

export class AdminApiClient extends BaseApiClient {
  async refresh(): Promise<RequestResponse> {
    return this.http.post(api.refresh(), null)
  }

  async getUserById(id: string): Promise<RequestResponse> {
    return this.http.get(api.user(id))
  }

  async updateUser(
    id: string,
    data: Partial<UserDataPayload>,
  ): Promise<RequestResponse<UserDataPayload>> {
    const url = api.user(id)
    return this.upsert({ url, data })
  }

  async getUserProfile(
    id: string,
  ): Promise<RequestResponse<ProfileDataPayload>> {
    return this.http.get(api.userProfile(id))
  }

  async upsertProfile(
    id: string,
    data: Partial<ProfileDataPayload>,
  ): Promise<RequestResponse<ProfileDataPayload>> {
    const url = api.userProfile(id)
    return this.upsert({ url, data })
  }

  private cleanParams<T extends Record<string, any>>(params: T): string {
    const cleaned = Object.keys(params).reduce(
      (accum, p) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (isNotEmpty(params[p])) accum[p] = params[p]
        // @ts-ignore
        if (typeof accum[p] === "string")
          // @ts-ignore
          accum[p] = normalizeWhitespace(accum[p])
        return accum
      },
      { limit: "25" } as Record<string, any>,
    )
    // { firstName: 'Billy', lastName: 'Joel' } => 'firstName=Billy&lastName=Joel'
    return new URLSearchParams(cleaned).toString()
  }

  async searchUsers({
    q,
    email,
    firstName,
    lastName,
  }:
    | { q?: string; firstName?: never; lastName?: never; email?: never }
    | {
        q?: never
        firstName?: string
        lastName?: string
        email?: string
      }): Promise<RequestResponse<UserDataPayload[]>> {
    return this.http.get({
      path: api.user(undefined),
      query: this.cleanParams({ search: q, email, firstName, lastName }),
    })
  }

  async addUserEmail({
    id,
    email,
  }: {
    id: string
    email: string
  }): Promise<RequestResponse<EmailPayload>> {
    return this.http.post(api.userEmail(id), { email })
  }

  async deleteUserEmail({
    id,
    emailId,
  }: {
    id: string
    emailId: number
  }): Promise<NoContentResponseType> {
    return this.http.delete(api.userEmail(id, emailId))
  }

  async deactivateUser(id: string): Promise<RequestResponse> {
    return this.http.delete(api.user(id))
  }

  async triggerPasswordReset({
    email,
  }: {
    email: string
  }): Promise<RequestResponse> {
    return this.http.post(api.forgotPassword, { email })
  }

  async getPasswordResetLink({
    email,
  }: {
    email: string
  }): Promise<RequestResponse<{ Link: string }>> {
    return this.http.post(api.resetPasswordLink, { email })
  }

  async addUserRole({
    id,
    roleId,
  }: {
    id: string
    roleId: string
  }): Promise<RequestResponse> {
    return this.http.post(api.userRole(id), { roleId })
  }

  async removeUserRole({
    id,
    roleId,
  }: {
    id: string
    roleId: string
  }): Promise<NoContentResponseType> {
    return this.http.delete(api.userRole(id, roleId))
  }

  async resendVerificationEmail(email: string): Promise<RequestResponse> {
    return this.http.post(api.userSendVerificationEmail(), { email })
  }

  async getEmailVerificationLink({
    email,
  }: {
    email: string
  }): Promise<RequestResponse<{ Link: string }>> {
    return this.http.post(api.authVerifyLink, { email })
  }

  async setPrimaryEmail({
    id,
    emailId,
  }: {
    id: string
    emailId: number
  }): Promise<RequestResponse> {
    return this.http.post(api.userSetPrimaryEmail(id), { emailId })
  }

  async listRoles(): Promise<RequestResponse<RolePayload[]>> {
    return this.http.get(api.roles())
  }

  async getStudentInfo(userId: string): Promise<RequestResponse<SSNPayload>> {
    return this.http.get(api.studentInfo({ userId }))
  }

  async setStudentInfo({
    userId,
    SSN,
  }: {
    userId: string
    SSN: string
  }): Promise<RequestResponse> {
    return this.http.post(api.studentInfo({ userId }), { SSN })
  }

  async _cleanOrg<T extends OrganizationPayload | OrganizationPayload[]>(
    response: Promise<RequestResponse<T>>,
  ): Promise<RequestResponse<T>> {
    const { data, ...rest } = await response
    const cleanOne = (org: OrganizationPayload): OrganizationPayload => {
      let { districtAdmin, supportAdmin } = org
      const invalidUUID = "00000000-0000-0000-0000-000000000000"
      if (districtAdmin === invalidUUID) districtAdmin = null
      if (supportAdmin === invalidUUID) supportAdmin = null
      return {
        ...org,
        districtAdmin,
        supportAdmin,
      }
    }
    return {
      ...rest,
      data: (Array.isArray(data) ? data.map(cleanOne) : cleanOne(data)) as T,
    }
  }

  async _cleanOrgUser<T extends OrgUserPayload | OrgUserPayload[]>(
    response: Promise<RequestResponse<T>>,
  ): Promise<RequestResponse<T>> {
    const { data, ...rest } = await response
    const cleanOne = (user: OrgUserPayload): OrgUserPayload =>
      omitBy(user, v => v === undefined) as OrgUserPayload
    return {
      ...rest,
      data: (Array.isArray(data) ? data.map(cleanOne) : cleanOne(data)) as T,
    }
  }

  async createOrg({
    name,
    hubspotAccountId,
    districtAdmin,
    supportAdmin,
    status,
  }: Partial<OrganizationPayload>): Promise<
    RequestResponse<OrganizationPayload>
  > {
    return this._cleanOrg(
      this.http.post(api.org(), {
        name,
        hubspotAccountId,
        districtAdmin,
        supportAdmin,
        status,
      }),
    )
  }

  async getOrg(
    orgId: number,
    { related }: { related?: string } = {},
  ): Promise<RequestResponse<OrganizationPayload>> {
    return this._cleanOrg(
      this.http.get({
        path: api.org(orgId),
        query: this.cleanParams({ related }),
      }),
    )
  }

  /**
   *
   * @param orgId
   * @param startDate YYYY-MM-DD
   * @param endDate YYYY-MM-DD (optional)
   * @returns
   */
  async getOrgMetrics(
    orgId: number,
    startDate: Date,
    endDate?: Date,
  ): Promise<RequestResponse<OrganizationProductMetrics>> {
    const params = {
      start_date: format(startDate, "yyyy-MM-dd"),
      end_date: endDate ? format(endDate, "yyyy-MM-dd") : undefined,
    }

    return this.http.get({
      path: api.orgProductMetrics(orgId),
      query: this.cleanParams(params),
    })
  }

  async getOrgs(): Promise<RequestResponse<OrganizationPayload[]>> {
    return this._cleanOrg(this.http.get(api.org()))
  }

  async searchOrgs({
    q,
    status,
    related,
  }: {
    q?: string
    status?: OrganizationStatus
    related?: string
  }): Promise<RequestResponse<OrganizationPayload[]>> {
    return this._cleanOrg(
      this.http.get({
        path: api.org(),
        query: this.cleanParams({ search: q, status, related }),
      }),
    )
  }

  async updateOrg(
    orgId: number,
    orgData: Partial<OrganizationPayload>,
  ): Promise<RequestResponse<OrganizationPayload>> {
    return this.http.patch(api.org(orgId), orgData)
  }

  async getOrgUsers(orgId: number): Promise<RequestResponse<OrgUserPayload[]>> {
    return this._cleanOrgUser(this.http.get(api.orgUser(orgId)))
  }

  async associateOrgUser(
    orgId: number,
    { userId }: { userId: string },
  ): Promise<RequestResponse<OrgUserPayload>> {
    return this._cleanOrgUser(this.http.post(api.orgUser(orgId), { userId }))
  }

  async dissociateOrgUser(
    orgId: number,
    { userId }: { userId: string },
  ): Promise<NoContentResponseType> {
    return this.http.delete(api.orgUser(orgId, userId))
  }

  async dissociateOrgUsers(
    orgId: number,
    userRemovals: { userId: string }[],
  ): Promise<NoContentResponseType> {
    return this.http.post(api.orgRemoveUsers(orgId), userRemovals)
  }

  async createUserDirect(payload: {
    email: string
    autoVerifyEmail?: boolean
    alternateEmail?: string | null
    firstName: string
    lastName: string
    degreeSeeking?: boolean
    campusCafeId?: number | null
  }): Promise<RequestResponse<OrgUserPayload>> {
    return this._cleanOrgUser(
      this.http.post(api.createUserDirect(), omitBy(payload, isEmpty)),
    )
  }

  async addOrgEntitlements(
    orgId: number,
    payloads: EntitlementUpdatePayload[],
  ): Promise<RequestResponse<EntitlementUpdatePayload[]>> {
    return this.http.post(api.orgEntitlement(orgId), payloads)
  }

  async uploadOrgEntitlements(
    orgId: number,
    file: File,
    { dryrun }: { dryrun?: boolean } = {},
  ): Promise<RequestResponse<BulkUploadPayload[]>> {
    return this.http.post(
      {
        path: api.orgEntitlementUpload(orgId),
        query: this.cleanParams({
          dryrun: dryrun && String(dryrun),
        }),
      },
      await file.text(),
      {
        headers: {
          "Content-Type": "text/csv",
          "Content-Disposition": `attachment; filename=${file.name}`,
        },
      },
    )
  }

  async listProducts(): Promise<RequestResponse<ProductPayload[]>> {
    return this.http.get(api.products)
  }
}

export type FLEXSearchQuery = Record<string, Optional<string | string[]>>
export class ContentServiceApiClient extends BaseApiClient {
  static prepareQuery({
    page = "1",
    sort_by = "created_at",
    page_size = "48",
    ...other
  }: FLEXSearchQuery): URLSearchParams {
    const searchParams = new URLSearchParams({
      page: page as string,
      sort_by: sort_by as string,
      page_size: page_size as string,
    })
    const val = Object.keys({ ...pickBy(other, isNotEmpty) }).reduce(
      (accum, paramName) => {
        let param = other[paramName]
        // the user provided search text needs whitespace sanitation
        if (typeof param === "string") {
          param = normalizeWhitespace(param)
        }
        if (Array.isArray(param)) {
          param.forEach(paramItem => {
            accum.append(paramName, paramItem)
          })
        } else {
          accum.append(paramName, String(param))
        }
        return accum
      },
      searchParams,
    )

    return val
  }

  constructor(params?: BaseAPIOptions) {
    super({
      baseUrl: ContentAPI.baseUrl,
      trailingSlash: true,
      ...params,
    })
  }

  async lessonPlans({
    search = undefined,
    sort_by = undefined,
    page = "1",
    ...filters
  }: FLEXSearchQuery): Promise<
    RequestResponse<FLEXSearchResult<FLEXAssetPayload>>
  > {
    return CastSanitySearch(
      this.http.get({
        path: ContentAPI.lessonPlans,
        query: ContentServiceApiClient.prepareQuery({
          search,
          sort_by,
          page,
          ...filters,
        }),
      }),
      FLEXAssetPayloadBox,
    )
  }

  async lessonPlan(
    id: string,
  ): Promise<RequestResponse<FLEXIndividualLessonPayload>> {
    return cast(
      this.http.get({
        path: ContentAPI.lessonPlan(id),
      }),
      FLEXIndividualLessonPayloadBox,
    )
  }

  async collections({
    search = undefined,
    sort_by = undefined,
    page = "1",
    ...filters
  }: FLEXSearchQuery): Promise<
    RequestResponse<FLEXSearchResult<FLEXCollectionSearchPayload>>
  > {
    return CastSanitySearch(
      this.http.get({
        path: ContentAPI.collections,
        query: ContentServiceApiClient.prepareQuery({
          search,
          sort_by,
          page,
          ...filters,
          page_size: "24",
        }),
      }),
      FLEXCollectionSearchPayloadBox,
    )
  }

  async collection(
    id: string,
  ): Promise<RequestResponse<FLEXIndividualCollectionPayload>> {
    return cast(
      this.http.get({
        path: ContentAPI.collection(id),
      }),
      FLEXIndividualCollectionPayloadBox,
    )
  }

  async resources({
    search = undefined,
    sort_by = undefined,
    page = "1",
    ...filters
  }: FLEXSearchQuery): Promise<
    RequestResponse<FLEXSearchResult<FLEXAssetPayload>>
  > {
    return CastSanitySearch(
      this.http.get({
        path: ContentAPI.resources,
        query: ContentServiceApiClient.prepareQuery({
          search,
          sort_by,
          page,
          ...filters,
        }),
      }),
      FLEXAssetPayloadBox,
    )
  }

  async resource(id: string): Promise<RequestResponse<FLEXAssetPayload>> {
    return cast(
      this.http.get({
        path: ContentAPI.resource(id),
      }),
      FLEXAssetPayloadBox,
    )
  }

  async videos({
    search = undefined,
    sort_by = undefined,
    page = "1",
    ...filters
  }: FLEXSearchQuery): Promise<
    RequestResponse<FLEXSearchResult<FLEXAssetPayload>>
  > {
    return CastSanitySearch(
      this.http.get({
        path: ContentAPI.videos,
        query: ContentServiceApiClient.prepareQuery({
          search,
          sort_by,
          page,
          ...filters,
        }),
      }),
      FLEXAssetPayloadBox,
    )
  }

  async video(id: string): Promise<RequestResponse<FLEXAssetPayload>> {
    return cast(
      this.http.get({
        path: ContentAPI.video(id),
      }),
      FLEXAssetPayloadBox,
    )
  }

  async assessments({
    search = undefined,
    sort_by = undefined,
    page = "1",
    ...filters
  }: FLEXSearchQuery): Promise<
    RequestResponse<FLEXSearchResult<FLEXAssetPayload>>
  > {
    return CastSanitySearch(
      this.http.get({
        path: ContentAPI.assessments,
        query: ContentServiceApiClient.prepareQuery({
          search,
          sort_by,
          page,
          ...filters,
        }),
      }),
      FLEXAssetPayloadBox,
    )
  }

  async assessment(id: string): Promise<RequestResponse<FLEXAssetPayload>> {
    return cast(
      this.http.get({
        path: ContentAPI.assessment(id),
      }),
      FLEXAssetPayloadBox,
    )
  }

  async curriculum(slug: string): Promise<RequestResponse<FLEXCurriculumType>> {
    return cast(
      this.http.get({
        path: ContentAPI.curriculum(slug),
      }),
      FLEXCurriculumBox,
    )
  }

  async curricula(): Promise<RequestResponse<Array<FLEXCurriculumType>>> {
    return cast(
      this.http.get({
        path: ContentAPI.curricula,
      }),
      Type.Array(FLEXCurriculumBox),
    )
  }

  async standards({
    search = undefined,
    sort_by = undefined,
    page = "1",
    ...filters
  }: FLEXSearchQuery): Promise<
    RequestResponse<FLEXSearchResult<FLEXAssetPayload>>
  > {
    return CastSanitySearch(
      this.http.get({
        path: ContentAPI.standards,
        query: ContentServiceApiClient.prepareQuery({
          search,
          sort_by,
          page,
          ...filters,
        }),
      }),
      FLEXAssetPayloadBox,
    )
  }

  async packs({
    search = undefined,
    sort_by = undefined,
    page = "1",
    ...filters
  }: FLEXSearchQuery): Promise<
    RequestResponse<FLEXSearchResult<PROPackPayload>>
  > {
    return CastSanitySearch(
      this.http.get({
        path: ContentAPI.packs,
        query: ContentServiceApiClient.prepareQuery({
          search,
          sort_by,
          page,
          ...filters,
          page_size: "24",
        }),
      }),
      PROPackPayloadBox,
    )
  }

  async implementations(
    product: "flex" | "pro",
  ): Promise<RequestResponse<ImplementationSearchResult>> {
    return cast(
      this.http.get({
        path: ContentAPI.implementations,
        query: new URLSearchParams({ product }),
      }),
      ImplementationSearchResultBox,
    )
  }

  async implementation(
    product: "flex" | "pro",
    slug: string,
  ): Promise<RequestResponse<ImplementationDetailPayload>> {
    return cast(
      this.http.get({
        path: ContentAPI.implementation(slug),
        query: new URLSearchParams({ product }),
      }),
      ImplementationDetailPayloadBox,
    )
  }
}

export class TrackingApi {
  private readonly baseUrl: URL = TrackingAPI.baseUrl
  private readonly apiKey: string =
    process.env.NEXT_PUBLIC_EVENT_TRACKING_API_KEY!

  constructor({
    baseUrl,
    apiKey,
  }: Partial<{
    baseUrl: URL
    apiKey: string
  }> = {}) {
    if (baseUrl) this.baseUrl = baseUrl
    if (apiKey) this.apiKey = apiKey
  }

  buildUrl(path: string): string {
    const url = new URL(this.baseUrl)
    url.pathname = `${rstrip(url.pathname, "/")}/${lstrip(path, "/")}`
    return String(url)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async post(
    path: string,
    body: unknown,
    init?: RequestInit,
  ): Promise<RequestResponse<unknown>> {
    try {
      const response = await fetch(this.buildUrl(path), {
        method: "post",
        body: JSON.stringify(body),
        mode: "cors",
        headers: {
          "x-api-key": this.apiKey,
          "Accept": "application/json",
          "Content-Type": "application/json",
          ...init?.headers,
        },
      })
      return {
        status: response.status,
        data: await (async () => {
          const text = await response.text()
          try {
            return JSON.parse(text)
          } catch (e) {
            return text
          }
        })(),
      }
    } catch (e) {
      logger
        .withScope({
          tags: {
            caller: "TrackingApi.post",
            ...(typeof body === "object" ? body : {}),
          },
        })
        .error(e)
      return {
        status: HttpStatus.SERVICE_UNAVAILABLE,
        data: {
          error: Object.hasOwn(e as object, "message")
            ? (e as any).message
            : String(e),
        },
      }
    }
  }

  trackEvent(
    event: PickOptional<TrackingPayloadType, "event_time">,
  ): Promise<RequestResponse> {
    event.event_time = event.event_time || new Date().toISOString()
    return this.post(TrackingAPI.event, event)
  }
}

async function cast<S extends TSchema>(
  req: Promise<{ status: number; data: any; headers?: any }>,
  s: S,
): Promise<{
  status: number
  data: Static<S>
  headers: Record<string, string>
}> {
  const { status, data, headers } = await req
  return {
    status,
    data: Value.Cast(s, data),
    headers,
  }
}

type ThisConstructor<
  T extends { prototype: unknown } = { prototype: unknown },
> = T
type This<T extends ThisConstructor> = T["prototype"]

// would prefer to do this as a static method on BaseApiClient, but figuring out the types
// required types is not worth the effort.
export const ClientFromContext = <
  T extends
    | ThisConstructor<typeof ApiClient>
    | ThisConstructor<typeof AdminApiClient>
    | ThisConstructor<typeof ContentServiceApiClient>,
>(
  constructor: T,
  context?: GetServerSidePropsContext | NextPageContext,
): This<T> => {
  const headers: any = {}

  if (context?.req?.headers.baggage && context?.req?.headers["sentry-trace"]) {
    headers.baggage = context.req.headers.baggage
    headers["sentry-trace"] = context.req.headers["sentry-trace"]
  }

  if (context?.req?.headers.cookie) {
    headers.Cookie = context.req.headers.cookie
  }

  return new constructor({
    headers,
  })
}
