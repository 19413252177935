/**
 * Partition a list into two list; the first includes items matching the predicate and the second
 * includes those that do not match. If Boolean(excludeNulls), a predicate of `null` will be
 * excluded from either partition. */
import { range } from "~/util/index"

export const partition = <T>(
  list: T[],
  predicate: (v: T) => boolean | null,
  excludeNulls = false,
): [T[], T[]] => {
  return list.reduce(
    (accum, item) => {
      const pred = predicate(item)
      if (pred) {
        accum[0].push(item)
      } else if (!pred) {
        if (pred === false) {
          accum[1].push(item)
        } else if (!excludeNulls) accum[1].push(item)
      }
      return accum
    },
    <[T[], T[]]>[[], []],
  )
}

/**
 * Return the given list, excluding the indicated index
 * @param {T[]} list
 * @param {number} idx (-length, length), e.g. -1 is last item in list
 * @return {T[]}
 */
export const withoutIndex = <T>(list: T[], idx: number): T[] => {
  console.assert(Math.abs(idx) <= list.length - 1, "Index out of bounds.")
  // convert convert negative indexes appropriately
  idx = (list.length + idx) % list.length
  return list.slice(0, idx).concat(list.slice(idx + 1))
}

/**
 * Return the given Array in the order of corresponding indexes. Sort is not stable
 * for items not enumerated in the index list.
 *
 * @param {T[]} list items to be sorted
 * @param {number[]} order list of indexes corresponding to the desired order of the items
 * @returns {T[]} sorted list
 *
 * @example
 *
 * ```ts
 * orderAs([2,8,100], [2,1,0]) === [100,8,2]
 * orderAs([2,8,100,1000], [null,1,0]) ~= [8,2,100,1000]
 * ```
 */
export const orderAs = <T>(list: T[], order?: number[] | null): T[] => {
  if (!order?.length) return list
  const indexSet = new Set(range(list.length))
  const result: T[] = []
  order?.forEach(idx => {
    if (indexSet.has(idx)) {
      indexSet.delete(idx)
      result.push(list[idx])
    }
  })
  // for loop would be more efficient, but older browsers don't support
  // iterating over IterableIterator
  return result.concat(Array.from(indexSet.values()).map(idx => list[idx]))
}

/**
 * Return the given Array in the order of corresponding ids. Sort is not stable
 * for items not enumerated in the id list.
 *
 * @param {T[]} list items to be sorted
 * @param {I[]} orderedIds list of ids ordered by the desired ordering of the items
 * @return {T[]} sorted list
 *
 * @example
 *
 * ```ts
 * orderFrom([id:3,id:2,id:4], [2,4,3]) === [id:2,id:4,id:3]
 * orderFrom([id:3,id:2,id:4], [null,null,null]) ~= [id:3,id:2,id:4]
 */
export function orderFrom<
  I extends string | number,
  T extends { id: I } | null,
>(list: T[], orderedIds?: I[] | null): T[] {
  if (!orderedIds?.length) return list
  const indexMap = list.reduce((accum, item, idx) => {
    if (item?.id !== undefined) accum.set(item.id, idx)
    return accum
  }, new Map<I, number>())
  const result: T[] = []
  orderedIds?.forEach(id => {
    if (indexMap.has(id)) {
      result.push(list[indexMap.get(id)!])
      indexMap.delete(id)
    }
  })
  // for loop would be more efficient, but older browsers don't support
  // iterating over IterableIterator
  return result.concat(Array.from(indexMap.values()).map(idx => list[idx]))
}

type NumberGen = () => number
/**
 * Monotonically increasing sequential number generator, starting with `start`. */
export const sequenceGenerator = (start: number | null = null): NumberGen => {
  let counter = (start ?? 0) - 1
  return () => {
    counter += 1
    return counter
  }
}

/**
 * Useful for generating an identifier which is guaranteed to be unique anywhere in the application.
 */
export const globalId = sequenceGenerator(0)
