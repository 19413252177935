import { styled } from "@mui/material/styles"
import { MaterialDesignContent } from "notistack"

export const StyledMaterialDesignContent = styled(MaterialDesignContent)(
  () => ({
    "&.notistack-MuiContent": {
      fontSize: "1rem",
    },
    "&.notistack-MuiContent-success": {
      backgroundColor: "#38853C",
    },
  }),
)
