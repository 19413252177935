import "isomorphic-fetch"
import HttpStatus from "http-status"
import normalizeUrl from "normalize-url"
import logger from "@/core/util/logger"
import { api } from "@/urls"
import { HttpRequestError } from "~/errors/http-request-error"
import { isNotEmpty } from "~/util"
import { extractCookiesFromHeader, getCSRFCookie } from "~/util/cookie"

const REQUEST_TIMEOUT_MS = 30000

const HTTP = {
  HEAD: "HEAD",
  OPTION: "OPTION",
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
} as const
type HttpMethod = typeof HTTP[keyof typeof HTTP]

export type RequestOptions = {
  headers?: Record<string, string>
  accessToken?: string | null
  csrfToken?: string | null
  attachCookie?: boolean
}
type RequestParams = RequestOptions & {
  method: HttpMethod
  url: Request | string
  data?: JSONValue
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RequestResponse<T = any> = {
  status: Response["status"]
  data: T
  headers?: Record<string, string>
}
type ErrMetaData = RequestResponse & {
  statusText: Response["statusText"]
}
type RequestError = Error & { metadata: ErrMetaData }

const requiresCSRF = (() => {
  const csrfSet = new Set<HttpMethod>([
    HTTP.POST,
    HTTP.PATCH,
    HTTP.DELETE,
    HTTP.PUT,
  ])
  return (method: HttpMethod) => csrfSet.has(method)
})()

// TODO (ch931): extract `request` and `HttpClient` this into its own standalone OSS package
// eslint-disable-next-line complexity
const request = async ({
  url,
  method = HTTP.GET,
  headers,
  data,
  accessToken,
  csrfToken,
  attachCookie = false,
}: RequestParams): Promise<RequestResponse> => {
  try {
    const includeBodyOption =
      !(<string[]>[HTTP.HEAD, HTTP.OPTION, HTTP.GET, HTTP.DELETE]).includes(
        method,
      ) && isNotEmpty(data)
    const authHeader =
      accessToken && !headers?.Authorization
        ? { Authorization: `Bearer ${accessToken}` }
        : {}

    const csrfHeader = csrfToken ? { "X-CSRFToken": `${csrfToken}` } : {}

    const options = {
      method,
      headers: {
        ...headers,
        ...authHeader,
        ...csrfHeader,
      },
      redirect: "follow",
      credentials: "include",
      ...(includeBodyOption
        ? { body: typeof data === "string" ? data : JSON.stringify(data) }
        : {}),
    } as RequestInit

    const response = await fetch(url, options)

    let responseData = <Record<string, JSONValue>>{}
    try {
      // if response.json() hangs (happens when the response is given bad headers!), we give
      // up after REQUEST_TIMEOUT_MS
      responseData = await Promise.race([
        response.json(),
        timeout(REQUEST_TIMEOUT_MS),
      ])
    } catch (err) {
      // ignore errors, we're just trying to capture any content
      // in the body of the response if there is any, and if there
      // is not, we don't care
    }

    if (responseData._timeout) {
      logger
        .withScope({
          tags: { caller: "http-client.request", url: String(url) },
        })
        .error(responseData._timeout)
      throw new Error(
        `Request timeout of ${
          REQUEST_TIMEOUT_MS / 1000
        } seconds occurred for ${url}`,
      )
    }

    const responseStatusClass = HttpStatus[`${response.status}_CLASS`]

    if (responseStatusClass !== HttpStatus.classes.SUCCESSFUL) {
      const errorMessageData = [
        `(${response.status})`,
        `${response.statusText}`,
      ]

      Object.keys(responseData).forEach(key => {
        errorMessageData.push(`[${key}: ${responseData[key]}]`)
      })

      const err = new Error(errorMessageData.join(" ")) as RequestError
      err.metadata = {
        status: response.status,
        statusText: response.statusText,
        data: responseData,
      }

      throw err
    }

    if (attachCookie) {
      responseData.cookie =
        extractCookiesFromHeader(response.headers.get("set-cookie")) ?? ""
    }

    return {
      status: response.status,
      data: responseData,
      headers: Object.fromEntries(response.headers.entries()),
    }
  } catch (err) {
    const metadata = (err as RequestError).metadata ?? {
      status: HttpStatus.SERVER_ERROR,
      statusText: "Server Error",
    }
    throw new HttpRequestError(metadata, err)
  }
}

type URLOptions =
  | string
  | {
      path: string
      query?: string | URLSearchParams
    }
type HttpClientType = typeof HttpClient
export type HttpClientMethods = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  [K in keyof HttpClientType]: HttpClientType[K] extends Function ? K : never
}
export class HttpClient {
  readonly baseUrl: string

  readonly accessToken: RequestOptions["accessToken"]

  readonly csrfToken: RequestOptions["csrfToken"]

  private _headers: Record<string, string>

  private readonly _trailingSlash: boolean = true

  get headers(): Record<string, string> {
    return {
      ...this.defaultHeaders,
      ...this._headers,
    }
  }

  updateHeaders(headers: Record<string, string>): void {
    this._headers = {
      ...this._headers,
      ...headers,
    }
  }

  get defaultHeaders(): Record<string, string> {
    return { "Content-Type": "application/json" }
  }

  constructor(
    baseUrl: string,
    accessToken: RequestOptions["accessToken"] = null,
    csrfToken: RequestOptions["csrfToken"] = null,
    headers: Record<string, string> = {},
    trailingSlash = true,
  ) {
    this.baseUrl = baseUrl
    this.accessToken = accessToken
    this.csrfToken = csrfToken
    this._trailingSlash = trailingSlash
    this._headers = headers
  }

  buildUrl(urlOptions: URLOptions): string {
    let path, query
    if (typeof urlOptions === "string") {
      path = urlOptions
    } else if (isNotEmpty(urlOptions)) {
      ;({ path, query } = urlOptions)
    }
    let url = normalizeUrl(`${this.baseUrl}/${path}`)

    // force a trailing slash
    url = this._trailingSlash && url[url.length - 1] !== "/" ? `${url}/` : url

    if (isNotEmpty(query)) {
      url = `${url}?${query}`
    }

    return url
  }

  async buildOptions({
    method,
    headers = {},
    accessToken = this.accessToken,
    csrfToken = this.csrfToken,
    ...options
  }: RequestParams): Promise<RequestParams> {
    return {
      method,
      headers: {
        ...this.headers,
        ...headers,
      },
      accessToken,
      csrfToken:
        csrfToken ??
        (await (async () => {
          if (!requiresCSRF(method)) return null
          const _csrfToken = getCSRFCookie()
          if (_csrfToken) return _csrfToken
          const {
            data: { csrftoken },
          } = await this.get(api.refresh(), { attachCookie: true, headers: {} })
          return isNotEmpty(csrftoken) ? String(csrftoken) : null
        })()),
      ...options,
    }
  }

  async get(
    urlOptions: URLOptions,
    options = <RequestOptions>{},
  ): Promise<RequestResponse> {
    const url = this.buildUrl(urlOptions)

    return request(
      await this.buildOptions({ url, method: HTTP.GET, ...options }),
    )
  }

  async delete(
    urlOptions: URLOptions,
    options = <RequestOptions>{},
  ): Promise<RequestResponse> {
    const url = this.buildUrl(urlOptions)

    return request(
      await this.buildOptions({
        url,
        method: HTTP.DELETE,
        ...options,
      }),
    )
  }

  async post(
    urlOptions: URLOptions,
    body?: JSONValue,
    options = <RequestOptions>{},
  ): Promise<RequestResponse> {
    const url = this.buildUrl(urlOptions)

    return request(
      await this.buildOptions({
        url,
        method: HTTP.POST,
        data: body,
        ...options,
      }),
    )
  }

  async patch(
    urlOptions: URLOptions,
    body: JSONValue,
    options = <RequestOptions>{},
  ): Promise<RequestResponse> {
    const url = this.buildUrl(urlOptions)

    return request(
      await this.buildOptions({
        url,
        method: HTTP.PATCH,
        data: body,
        ...options,
      }),
    )
  }

  async put(
    urlOptions: URLOptions,
    body: JSONValue,
    options = <RequestOptions>{},
  ): Promise<RequestResponse> {
    const url = this.buildUrl(urlOptions)

    return request(
      await this.buildOptions({
        url,
        method: HTTP.PUT,
        data: body,
        ...options,
      }),
    )
  }
}

async function timeout(ms: number): Promise<{ _timeout: string }> {
  await sleep(ms)
  return { _timeout: `could not resolve content body in ${ms} milliseconds` }
}

function sleep(milliseconds = 0): Promise<void> {
  return new Promise(resolve => {
    setTimeout(resolve, milliseconds)
  })
}
