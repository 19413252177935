import { createContext, ReactElement, ReactNode } from "react"
import type { YupTranslationSet } from "@/lang/yup"

type YupContextType = {
  translationSet?: YupTranslationSet
  locale: string
}
export const YupContext = createContext<YupContextType>({} as YupContextType)

export function YupProvider({
  translationSet,
  locale,
  children,
}: YupContextType & {
  children: ReactNode
}): ReactElement {
  return (
    <YupContext.Provider value={{ locale, translationSet }}>
      {children}
    </YupContext.Provider>
  )
}
